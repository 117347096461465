import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import moment from 'moment';
import { history } from '../../helpers';

const EVENTS = ['click', 'load', 'scroll'];


const SessionTimeout = ({ isAuthenticated, logout }) => {
  const [,setSecond] = useState(0);

  let timeStamp;
  const warningInactiveInterval = useRef();
  const startTimerInterval = useRef();

  const warningInactive = useCallback((timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 2;
      const popTime = 1;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.hours();
      const leftSecond = 60 - diff.seconds();

      if (minPast === popTime) {
        setSecond(leftSecond);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem('lastTimeStamp');

        return Promise.resolve(() => logout())
          .then(() => {
            history.push('/');
          });
      }
    }, 1000);
  }, [setSecond, logout]);

  // start inactive check
  let timeChecker = useCallback(() => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 1000);
  }, [warningInactive]);

  // warning timer


  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }
    timeChecker();
  }, [isAuthenticated, timeChecker]);

  useEffect(() => {
    EVENTS.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      clearTimeout(warningInactiveInterval.current);
    };
  }, [resetTimer, timeChecker]);

  // change fragment to modal and handleclose func to close
  return (<Fragment/> );
};

export default SessionTimeout;