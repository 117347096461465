export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN_GOOGLE_REQUEST: 'USERS_LOGIN_GOOGLE_REQUEST',
    LOGIN_GOOGLE_SUCCESS: 'USERS_LOGIN_GOOGLE_SUCCESS',
    LOGIN_GOOGLE_FAILURE: 'USERS_LOGIN_GOOGLE_FAILURE',

    VERIFY_ACTIVATE_REQUEST: 'USERS_VERIFY_ACTIVATE_REQUEST',
    VERIFY_ACTIVATE_SUCCESS: 'USERS_VERIFY_ACTIVATE_SUCCESS',
    VERIFY_ACTIVATE_FAILURE: 'USERS_VERIFY_ACTIVATE_FAILURE',

    ACTIVATE_REQUEST: 'USERS_ACTIVATE_REQUEST',
    ACTIVATE_SUCCESS: 'USERS_ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE: 'USERS_ACTIVATE_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    FETCH_REQUEST: 'USERS_FETCH_REQUEST',
    FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
    FETCH_FAILURE: 'USERS_FETCH_FAILURE',

    CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    SAVE_REQUEST: 'USERS_SAVE_REQUEST',
    SAVE_SUCCESS: 'USERS_SAVE_SUCCESS',
    SAVE_FAILURE: 'USERS_SAVE_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    RESEND_INVITATION_REQUEST: 'USERS_RESEND_INVITATION_REQUEST',
    RESEND_INVITATION_SUCCESS: 'USERS_RESEND_INVITATION_SUCCESS',
    RESEND_INVITATION_FAILURE: 'USERS_RESEND_INVITATION_FAILURE',
};
