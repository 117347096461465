import { buildBaseHeaders } from './request.service';
import userService from './user.service';

export default {
  askNewForEmail,
  update,
};

const url = window.location.origin + '/api';

function update(token, password) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...buildBaseHeaders('PATCH'),
    },
    body: JSON.stringify({ token, password: password.trim() })
  };

  return fetch(`${url}/users/admin/credentials`, requestOptions)
    .then(handleResponse);
}

function askNewForEmail(email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...buildBaseHeaders('POST'),
    },
    body: JSON.stringify({ email: email.trim() })
  };

  return fetch(`${url}/users/admin/password`, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    let data = null;

    try {
      data = text && JSON.parse(text);
    } catch (error) {
      data = text;
    }

    if (!response.ok) {
      if (response.status === 401)
        // auto logout if 401 response returned from api
        userService.logout();

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}