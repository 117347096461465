import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { tokenHasExpired, userHasRight } from '../helpers';

const PrivateRoute = ({ component: Component, permission, ...rest }) => {
    const { pathname, search } = useLocation();
    const query = new URLSearchParams(search)

    return (
        <Route {...rest} render={(props) => {
            if (tokenHasExpired() && permission !== 'admin:activation') {
                const code = query.get('code');
                const state = {
                    from: pathname,
                    ...(code ? { code } : {}),
                };

                return (<Redirect to={{ pathname: '/login', state }} />);
            }

            if (!userHasRight(permission))
                return (<Redirect to={{ pathname: '/home' }} />);

            return (<Component {...props} />);
        }} />
    );
};

export default PrivateRoute;
