import { COLORS, TYPOGRAPHY } from '../../foundation';

export default {
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/ef-diamond-min.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    backgroundPosition: 'center',
    backgroundColor: COLORS.GREY_LIGHT_DEFAULT,
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatar: {
    backgroundColor: COLORS.PRISM.LIGHT_BLUE,
    marginTop: 10,
    marginBottom: 10,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  instruction: {
    paddingTop: 32,
    paddingBottom: 16,
    alignSelf: 'flex-start',
  },
  submit: {
    marginTop: 20,
    marginBottom: 20,
    width: '50%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 50,
    paddingRight: 50,
  },
  buttonProgress: {
    color: COLORS.WHITE.DEFAULT,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};