// @flow

const PRIMARY = {
  DEFAULT: '#348FFC',
  HOVER: '#59A4FF',
  DISABLED: '#E3E3E3',
};

const GREY_LIGHT = {
  DEFAULT: '#FAFAFA',
  HOVER: '#F6F6F6',
  DISABLED: '#E3E3E3',
};

const GREY_MEDIUM = {
  DEFAULT: '#EDEDED',
  HOVER: '#F6F6F6',
  DISABLED: '#E3E3E3',
};

const GREY_DARK = {
  DEFAULT: '#CCCCCC',
  HOVER: '#AAAAAA',
  OUTLINE_FOCUSED: '#AAAAAA',
  OUTLINE_DISABLED: '#E3E3E3',
  DISABLED: '#E3E3E3',
};

const TRUE_BLACK = {
  DEFAULT: '#000',
};

const BLACK = {
  DEFAULT: '#222A34',
  DISABLED: '#E3E3E3',
};

const WHITE = {
  DEFAULT: '#FFFFFF',
  HOVER: '#F6F6F6',
};

const SUCCESS = {
  DEFAULT: '#2ACF60',
  DISABLED: '#E3E3E3',
};

const ERROR = {
  DEFAULT: '#FF676D',
  DISABLED: '#E3E3E3',
};

const GOOGLE = {
  RED: '#EE4433',
};

const WARNING = {
  DEFAULT: '#E07514',
  DISABLED: '#E3E3E3',
};

const PRISM = {
  PINK: '#FF9C92',
  PURPLE: '#BC8DCD',
  LIGHT_BLUE: '#52D3E0',
  BLUE: '#5A9DED',
  GREEN: '#A6DC49',
  YELLOW: '#FFE94D',
};

const TEXT = {
  DEFAULT: '#222A34',
  DISABLED: '#E3E3E3',
  SECONDARY_DEFAULT: '#696969',
  SECONDARY_DISABLED: '#E3E3E3',
  SECONDARY_HOVER: '#AAAAAA',
  DISABLED_DEFAULT: '#AAAAAA',
  DISABLED_DISABLED: '#E3E3E3',
  PLACEHOLDER_DEFAULT: '#CCCCCC',
  PLACEHOLDER_DISABLED: '#E3E3E3',
  ACCENTUATED: '#348FFC',
};

const ALERT = {
  DEFAULT: BLACK.DEFAULT,
  ERROR: '#EC484E',
  SUCCESS: '#2ACF60',
};

export default {
  PRIMARY,
  GREY_LIGHT,
  GREY_MEDIUM,
  GREY_DARK,
  BLACK,
  TRUE_BLACK,
  WHITE,
  SUCCESS,
  ERROR,
  WARNING,
  PRISM,
  TEXT,
  ALERT,
  GOOGLE,
};
