import { adminModalConstants } from '../constants';


const initialState = {
  open: false,
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    roleId: 1,
  },
  roles: [],
  error: {},
};

export function adminModal(state = {...initialState}, action) {
  switch (action.type) {
    case adminModalConstants.OPEN: {
      const newState = { ...state, open: true };

      newState.user = action.user ? action.user : { ...initialState.user };

      return newState;
    }
    case adminModalConstants.CLOSE:
      return { ...state, open: false, error: {} };
    case adminModalConstants.SET_FIRSTNAME: {
      const { user } = state;

      const newUser = {
        ...user,
        firstName: action.firstName,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case adminModalConstants.SET_LASTNAME: {
      const { user } = state;

      const newUser = {
        ...user,
        lastName: action.lastName,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case adminModalConstants.SET_EMAIL: {
      const { user } = state;

      const newUser = {
        ...user,
        email: action.email,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case adminModalConstants.SET_ROLE: {
      const { user } = state;

      const newUser = {
        ...user,
        roleId: action.roleId,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case adminModalConstants.FETCH_ROLES_SUCCESS:
      return { ...state, roles: action.roles };
    case adminModalConstants.FETCH_ROLES_FAILURE:
      return { ...state, error: action.error };
    default:
      return state
  }
}