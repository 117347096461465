import { userConstants } from '../constants';
import { userService } from '../services';

const token = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');
const user = localStorage.getItem('user');

let initialState = {
    loggingIn: false,
    loggedIn: false,
    user: null,
    navMap: {},
};

if (token && refreshToken)
    initialState = { loggedIn: true, user: JSON.parse(user), navMap: userService.getAuthorizedNavMap(user) };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_GOOGLE_REQUEST:
        case userConstants.LOGIN_REQUEST: {
            return {
                ...state,
                loggingIn: true,
            };
        }

        case userConstants.LOGIN_GOOGLE_SUCCESS:
        case userConstants.LOGIN_SUCCESS: {
            const navMap = userService.getAuthorizedNavMap(action.user);

            return {
                ...state,
                loggingIn: false,
                loggedIn: true,
                user: action.user,
                navMap: navMap,
            };
        }
        case userConstants.LOGIN_GOOGLE_FAILURE:
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggingIn: false,
            };
        case userConstants.LOGOUT:
            return initialState;
        default:
            return state
    }
}