import { COLORS, TYPOGRAPHY } from '../../foundation';


export default {
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }
};
