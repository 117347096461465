import React, { useState, useCallback } from 'react';
import { css } from 'aphrodite';

import { history } from '../../helpers';

import { COLORS } from '../../foundation';
import { PRISM_PART } from '../../utils/Enum';

import styles, { extendedStyles } from './PrismNavigator.style';

const COLOR_BY_NAME = {
  [PRISM_PART.YELLOW]: COLORS.PRISM.YELLOW,
  [PRISM_PART.PINK]: COLORS.PRISM.PINK,
  [PRISM_PART.PURPLE]: COLORS.PRISM.PURPLE,
  [PRISM_PART.GREEN]: COLORS.PRISM.GREEN,
  [PRISM_PART.BLUE]: COLORS.PRISM.BLUE,
  [PRISM_PART.LIGHT_BLUE]: COLORS.PRISM.LIGHT_BLUE,
}

const POINTS_BY_NAME = {
  [PRISM_PART.YELLOW]: '212.5 0 0 380.66 212.5 256.66 212.5 0',
  [PRISM_PART.PINK]: '212.5 0 425 380.66 212.5 256.66 212.5 0',
  [PRISM_PART.PURPLE]: '212.5 256.66 212.5 520.66 425 380.66 212.5 256.66',
  [PRISM_PART.GREEN]: '0 380.66 212.5 520.66 212.5 256.66 0 380.66',
  [PRISM_PART.BLUE]: '425 426.66 212.5 747.32 212.5 565.66 425 426.66',
  [PRISM_PART.LIGHT_BLUE]: '212.5 747.32 212.5 565.66 0 426.66 212.5 747.32',
}

const SMALL_SCREEN = 786;

const Prism = ({ name, id, content, onClick, disabled, prismBorder, onPrismHover, selected }) => {
 // eslint-disable-next-line no-unused-vars
  const [isHover, setIsHover] = useState(false);

  const toggleHover = useCallback(
    (isHover) => {
      setIsHover(isHover)

      return onPrismHover(name, isHover);
    },
    [name, onPrismHover],
  );

  let prismStyle = {
    ...styles.prism,
    ...styles[name],
    // ...(selected ? { fill: COLOR_BY_NAME[name] } : {}),
    ...(disabled ? { stroke: COLORS.TEXT.DISABLED_DEFAULT, strokeWidth: 1 } : { stroke: COLOR_BY_NAME[name], strokeWidth: 1 }),
    ...(prismBorder ? { stroke: COLORS.TEXT.DEFAULT, strokeWidth: 2 } : {}),
  };

  if (disabled) {
    prismStyle = {
      ...prismStyle,
      opacity: 0.2,
      cursor: 'not-allowed',
    };
  }

  return (
    <polygon
      id={name}
      points={POINTS_BY_NAME[name]}
      onClick={onClick.bind(null, name)}
      onMouseEnter={toggleHover.bind(null, true)}
      onMouseLeave={toggleHover.bind(null, false)}
      style={prismStyle}
      className={css(extendedStyles.prism, !disabled && extendedStyles[name], selected && extendedStyles[`selected-${name}`])}
    />
  );
}

export default class PrismNavigator extends React.Component {
  static defaultProps = {
    selectedItem: '',
    prismBorder: false,
    autoResize: false,
    onPrismHover: () => {},
  };

  constructor(props) {
    super(props);

    this.resizeListener = null;
    this.state = { windowWidth: window.innerWidth, windowHeight: window.innerHeight };
  }

  componentDidMount() {
    const { autoResize } = this.props;

    if (autoResize)
      this.resizeListener = window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    if (this.resizeListener)
      window.addEventListener('resize', this.handleResize);
  }

  render() {
    const { size: sizeFromProps, autoResize, style } = this.props;

    let size = sizeFromProps;

    if (autoResize)
      size = this.getSizeFromWindowWidth();

    const wrapperStyle = {
      ...styles.wrapper,
      width: size,
      ...style,
    };

    return (
      <div style={wrapperStyle}>
        <svg
          id='prism'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 425 747.32'
        >
          {this.renderPrisms()}
        </svg>
      </div>
    );
  }

  renderPrisms = () => {
    const { navMap, prismBorder, onPrismHover, selectedItem } = this.props;
    const prismParts = Object.values(PRISM_PART);

    return prismParts.map((prismPart, i) => {
      const disabled = navMap[prismPart].disabled;

      return (
        <Prism
          key={prismPart}
          name={prismPart}
          id={i}
          disabled={disabled}
          content=''
          onClick={this.handleClick}
          onPrismHover={onPrismHover}
          prismBorder={prismBorder}
          selected={selectedItem && selectedItem === prismPart}
        />
      );
    });
  }

  handleClick = (prismName) => {
    const { navMap } = this.props;
    const config = navMap[prismName];

    if (!config.disabled)
      history.push(config.route);
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  getSizeFromWindowWidth = () => {
    const { windowWidth, windowHeight } = this.state;

    let newSize = (windowHeight / 2) - 50;

    if (windowWidth < SMALL_SCREEN)
      newSize -= 40;

    return newSize;
  }
}