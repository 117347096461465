import React from 'react';
import {
  Avatar,
  Icon,
  Fade,
  MenuItem,
  Menu,
} from '@material-ui/core';

import { css } from 'aphrodite';

import styles, { extendedStyles } from './ProfileMenu.style';


export default class ProfileMenu extends React.PureComponent {
  wrapperRef = null;

  static defaultProps = {
    type: 'standard',
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.state = {
      open: false,
      anchor: null,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { initials, type } = this.props;
    const { open, anchor } = this.state;

    const isOutline = type === 'outline';

    const outlineStyle = isOutline ? styles.outline : {};
    const focusStyle = (isOutline && open) ? styles.opened : {};

    const avatarStyle = {
      ...styles.avatar,
      ...outlineStyle,
      ...focusStyle,
    };

    return (
      <div style={styles.menuContainer}>
        <div onClick={this.handleSetTargetEl}>
          <Avatar style={avatarStyle} className={css(isOutline && extendedStyles.avatar)}>
            {
              initials
              ? <span style={styles.initials}>{ initials }</span>
              : <Icon className='fa fa-user' />
            }
          </Avatar>
        </div>
        <Menu
          id='profile-option-list'
          onClose={this.handleClose}
          anchorEl={anchor}
          getContentAnchorEl={null}
          open={open}
          TransitionComponent={Fade}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          placement='bottom'
          disableAutoFocusItem
          style={styles.listContainer}
        >
          {this.renderItems()}
        </Menu>
      </div>
    );
  }

  renderItems = () => {
    const { options } = this.props;

    return options.map((option) => {
      const { id, name, callback, icon } = option;

      return (
        <MenuItem key={id} onClick={callback} style={styles.optionItem}>
          <Icon className={`fa fa-${icon}`} style={styles.optionIcon}/>
          <span>{name}</span>
        </MenuItem>
      );
    })
  }

  handleClose = () => {
    this.setState({ open: false, anchor: null });
  }

  handleSetTargetEl = (event) => {
    this.setState({ open: true, anchor: event.currentTarget });
  }
}
