import React from 'react';

import { connect } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import { AdminModalActions } from '../../../../../../actions';

import styles from './AdminEditionModal.style';


class AdminEditionModal extends React.Component {
  _descriptionElementRef = null;

  constructor() {
    super();

    this._descriptionElementRef = React.createRef();
    this.state = {
      saving: false,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      },
    }
  }

  componentDidMount() {
    const { fetchRoles } = this.props;

    return Promise.resolve(fetchRoles());
  }

  render() {
    const { open, user, onClose, error } = this.props;
    const { id, firstName, lastName } = user;
    const { saving } = this.state;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id="admin-modal-title">
          <span style={styles.dialogTitle}>
            { !id ? 'Create new admin' : `Admin ${firstName} ${lastName}`}
          </span>
        </DialogTitle>
        <DialogContent dividers={true}>
          {this.renderForm()}
        </DialogContent>
        <DialogActions style={styles.actionContainer}>
          <div style={styles.errorMessage}>
            { error && error.message ? error.message : '' }
          </div>
          <div style={styles.buttonWrapper}>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              disabled={saving}
              style={styles.saveButton}
            >
              { !id ? 'Create' : 'Save' }
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  renderForm = () => {
    const { user: { firstName, lastName, email, id }} = this.props;
    const { errors } = this.state;

    return (
      <form noValidate style={styles.form}>
        <div style={styles.nameForm}>
          <TextField
            error={!!errors.firstName}
            helperText={errors.firstName}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First name"
            name="firstName"
            autoComplete="firstname"
            autoFocus
            value={firstName}
            onChange={this.handleChangeFirstName}
          />
          <TextField
            error={!!errors.lastName}
            helperText={errors.lastName}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last name"
            name="lastName"
            autoComplete="lastname"
            value={lastName}
            onChange={this.handleChangeLastName}
            style={styles.lastNameInput}
          />
        </div>
        <div style={styles.infoForm}>
          <TextField
            error={!!errors.email}
            helperText={errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={this.handleChangeEmail}
            disabled={!!id}
            style={styles.emailInput}
          />
          { this.renderRoles() }
        </div>
      </form>
    );
  }

  renderRoles = () => {
    const { user: { roleId }, roles } = this.props;

    return (
      <FormControl variant="outlined" style={styles.roleFormControl}>
        <InputLabel htmlFor="role-form-select">Role</InputLabel>
        <Select
          native
          label="Role"
          value={roleId}
          onChange={this.handleChangeRole}
          inputProps={{
            name: 'role',
            id: 'role-form-select',
          }}
        >
          {
            roles.map((role) => {
              const { id, label } = role;

              return (
                <option key={`admin-menu-${id}`} value={id}>
                  { label }
                </option>
              )
            })
          }
        </Select>
      </FormControl>
    )
  }

  handleChangeFirstName = (event) => {
    const { setFirstName } = this.props;
    const { value } = event.target;

    setFirstName(value);
  }

  handleChangeLastName = (event) => {
    const { setLastName } = this.props;
    const { value } = event.target;

    setLastName(value);
  }

  handleChangeEmail = (event) => {
    const { setEmail } = this.props;
    const { value } = event.target;

    setEmail(value);
  }

  handleChangeRole = (event) => {
    const { setRole } = this.props;
    const { value } = event.target;

    setRole(value);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ saving: true }, () => {
      const { user } = this.props;

      if (this.validate())
        return this.handleSave(user);

      this.setState({ saving: false });
    });
  }

  handleSave = (user) => {
    const { onSave, onClose } = this.props;

    return Promise.resolve(onSave(user))
      .then((res) => {
        const { error } = this.props;

        this.setState({ saving: false });

        if (!error.message)
          onClose();
      });
  }

  validate = () => {
    const { user: { email, firstName, lastName }} = this.props;
    const errors = {};
    let isValid = true;

    if (!firstName) {
      isValid = false;
      errors.firstName = "Please enter a first name.";
    }

    if (!lastName) {
      isValid = false;
      errors.lastName = "Please enter a last name.";
    }

    if (!email) {
      isValid = false;
      errors.email = "Please enter an email address.";
    } else {
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(email)) {
        isValid = false;
        errors.email = 'Please enter valid email address.';
      }
    }

    this.setState({ errors: errors });

    return isValid;
  }
}

function mapState(state) {
  const { error } = state.users;
  const { open, user, roles } = state.adminModal;

  return { open, user, roles, error };
}

const actionCreators = {
  setFirstName: AdminModalActions.setFirstName,
  setLastName: AdminModalActions.setLastName,
  setEmail: AdminModalActions.setEmail,
  setRole: AdminModalActions.setRole,
  fetchRoles: AdminModalActions.fetchRoles,
};

const connectedAdminEditionModal = connect(mapState, actionCreators)(AdminEditionModal)
export default connectedAdminEditionModal;
