
import React from 'react';

import { connect } from 'react-redux';

import { Loader } from '../../../../components';
import { REPORTS_TYPE } from '../../../../utils/Enum'

import { ReportActions } from '../../../../actions';

import {
  Grid,
  Typography,
  Paper,
  TextField,
} from '@material-ui/core';

import styles from './ReportConfiguration.style';

const LABEL_BY_TYPE = {
  [REPORTS_TYPE.ASSETS]: 'Assets link',
  [REPORTS_TYPE.FOCUS_AREA]: 'Focus Area link',
  [REPORTS_TYPE.FINANCE_OVERVIEW]: 'Financial overview link',
  [REPORTS_TYPE.OD]: 'Ongoing development link',
  [REPORTS_TYPE.ED]: 'ED link',
  [REPORTS_TYPE.EF_ERS]: 'EFers link',
}

class ReportConfiguration extends React.Component {
  componentDidMount() {
    const { reportsFetch } = this.props;

    return Promise.resolve(reportsFetch());
  }

  render() {
    const { loading } = this.props;

    if (!loading)
      return this.renderPage();

    return (
      <div style={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  renderPage = () => {
    return (
      <div style={styles.wrapper}>
        <div style={styles.contentWrapper}>
          <div styles={styles.titleWrapper}>
            <Typography variant="h3" component="h3" style={styles.pageTitle}>
              Report configuration
            </Typography>
            <Paper elevation={3} style={styles.formWrapper}>
              <Grid container justify="center" spacing={3} style={styles.listContainer}>
                { this.renderInputs() }
              </Grid>
            </Paper>
          </div>
        </div>
      </div>
    )
  }

  renderInputs = () => {
    const { reports: reportsProps, fieldsDisabled, error } = this.props;
    const reports = reportsProps || [];

    const reportsMap = reports.reduce((acc, r) => ({ ...acc, [r.slug]: r }), {});

    return Object.values(REPORTS_TYPE).map((type) => {
      const { slug, url } = reportsMap[type] || {};

      return (
        <Grid
          item
          xs={12}
          sm={6}
          key={`report-link-form-${type}`}
          style={styles.itemWrapper}
        >
          <TextField
            error={!!error}
            id={`report-input-${type}`}
            label={LABEL_BY_TYPE[type]}
            placeholder="Type to change the GDS link ..."
            value={url}
            onChange={(event) => this.handleChange(slug, event)}
            onBlur={() => this.handleSubmit(slug)}
            disabled={fieldsDisabled}
            variant="outlined"
            helperText={error}
            style={styles.field}
          />
        </Grid>
      );
    });
  }

  handleChange = (slug, event) => {
    const { reports: reportsProp, setReportUrl } = this.props;
    const reports = reportsProp || [];
    const report = reports.find((r) => r.slug === slug);
    const { value } = event.target;

    if (!report)
      return false;

    setReportUrl(report.id, value.trim());
  }

  handleSubmit = (slug) => {
    const { reports: reportsProp, updateReport } = this.props;
    const reports = reportsProp || [];
    const report = reports.find((r) => r.slug === slug);

    if (!report) return false;

    return updateReport(report);
  }
}

function mapState(state) {
  const {
    adminEdition: { reports, fieldsDisabled },
    loading,
    error,
  } = state.report;

  return { reports, loading, fieldsDisabled, error };
}

const actionCreators = {
  reportsFetch: ReportActions.getAll,
  setReportUrl: ReportActions.setUrl,
  updateReport: ReportActions.update,
};

const connectedReportConfigurationPage = connect(mapState, actionCreators)(ReportConfiguration);
export default connectedReportConfigurationPage;