import { authHeader } from '../helpers';
import { buildBaseHeaders } from './request.service';
import userService from './user.service';


export default {
  sendNotification,
};

const url = window.location.origin + '/api';

function sendNotification(notification) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...buildBaseHeaders('POST'),
      ...authHeader(),
    },
    body: JSON.stringify(notification)
  };

  return fetch(`${url}/notifications/sendUsers`, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401)
        // auto logout if 401 response returned from api
        userService.logout();

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}