import { authHeader } from '../helpers';
import { REPORTS_TYPE, PRISM_PART } from '../utils/Enum';
import { buildBaseHeaders } from './request.service';
import userService from './user.service';

export default {
  getReportUrl,
  getAllReports,
  update,
};

const url = window.location.origin + '/api';

function getReportUrl(reportName) {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...buildBaseHeaders('GET'),
      ...authHeader(),
    },
  };

  return fetch(`${url}/reports/getUrl/${reportName}`, requestOptions)
    .then(handleResponse);;
}

function getAllReports() {
  const requestOptions = {
    method: "GET",
    headers: {
      ...buildBaseHeaders('GET'),
      ...authHeader(),
    },
  };

  return fetch(`${url}/reports`, requestOptions)
    .then(handleResponse);
}

function update(report) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...buildBaseHeaders('PUT'),
      ...authHeader(),
    },
    body: JSON.stringify(report),
  };

  return fetch(`${url}/reports/${report.slug}`, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401)
          // auto logout if 401 response returned from api
          userService.logout();

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}