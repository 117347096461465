import React from 'react';
import { css } from 'aphrodite';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Divider,
} from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PieChartIcon from '@material-ui/icons/PieChart';

import styles, { extendedStyles } from './AdminDrawer.style';


const navigationItems = [{
  id: 'users-management',
  icon: SupervisorAccountIcon,
  label: 'Users management',
}, {
  id: 'notification-sender',
  icon: NotificationsIcon,
  label: 'Notification center',
}, {
  id: 'report-configuration',
  icon: PieChartIcon,
  label: 'Report configuration',
}];

export class AdminDrawer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    const { open } = this.state;

    return (
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{ style: styles.drawerPaper, className: css(extendedStyles.drawerPaper) }}
        style={styles.drawer}
        className={css(extendedStyles.drawer)}
      >
        <Divider />
        <List>
          { this.renderItems() }
        </List>
      </Drawer>
    );
  }

  renderItems = () => {
    const { onItemClick } = this.props;

    return navigationItems.map((item) => {
      const { id, label, icon: Icon } = item;

      return (
          <ListItem
            key={id}
            button
            onClick={onItemClick.bind(null, id)}
            style={styles.optionItem}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
      );
    })
  }
}

export default AdminDrawer;