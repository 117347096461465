import { StyleSheet } from 'aphrodite';
import { COLORS, TYPOGRAPHY } from '../../../../../../foundation';

export default {
  wrapper: {
    padding: 8,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    ...TYPOGRAPHY.H2,
    fontWeight: 'bold',
  },
  email: {
    ...TYPOGRAPHY.PS,
    color: COLORS.TEXT.SECONDARY_DEFAULT
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusWrapper: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: '100%',
    width: 28,
    height: 28,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 16,
  },
  status: {
    fontSize: 16,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  indicatorIcon: {
    fontSize: 16,
    width: 20,
    marginLeft: 10,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editButton: {
    color: COLORS.PRIMARY.DEFAULT,
    borderColor: COLORS.PRIMARY.DEFAULT,
  },
  deleteButton: {
    color: COLORS.ERROR.DEFAULT,
    borderColor: COLORS.ERROR.DEFAULT,
    marginLeft: 8,
  },
  resendButton: {
    color: COLORS.PRIMARY.DEFAULT,
    marginLeft: 8,
    padding: 2,
  },
  resendButtonDisabled: {
    color: COLORS.TEXT.DISABLED,
  },
  buttonIconHover: {
    marginRight: 8,
  },
  buttonIcon: {
    marginRight: -8,
  },
};

export const extendedStyles = StyleSheet.create({
  editButtonHovered: {
    ':hover': {
      width: 80,
    },
  },
  deleteButtonHovered: {
    ':hover': {
      width: 100,
    },
  },
});
