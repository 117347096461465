import { COLORS } from '../../foundation';

export default {
  wrapper: {
    height: 68,
    backgroundColor: COLORS.WHITE.DEFAULT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'fixed',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.2)',
  },
  logoWrapper: {
    marginLeft: 30,
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/logo-title.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: 68,
    width: 150,
  },
  title: {
    fontWeight: 'bold',
    height: '100%',
    color: COLORS.TEXT.DEFAULT,
    fontFamily: 'Genera-SemiBold',
    padding: 0,
  },
  secondTitleWord: {
    marginTop: -5,
  },
  titleLink: {
    textDecoration: 'none',
    display: 'inline-flex',
    flexDirection: 'column',
    marginLeft: 15,
  },
};
