
import React from 'react';

import { connect } from 'react-redux';

import { Loader } from '../../../../components';

import { NotificationForm } from './components/NotificationForm/NotificationForm';

import { UserActions, NotificationActions } from '../../../../actions';

import {
  Typography,
  Paper,
  Button,
} from '@material-ui/core';

import {
  Send as SendIcon,
} from '@material-ui/icons';

import styles from './NotificationSender.style';


class NotificationSender extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      formSubject: "",
      formContent: "",
      formTitle: "",
      formTarget: [],
      errors: {},
      sendLoading: false,
    };
  }

  componentDidMount() {
    const { usersFetch } = this.props;

    return Promise.resolve(usersFetch());
  }

  render() {
    const { loading } = this.props;

    if (!loading) return this.renderNotificationPage();

    return (
      <div style={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  renderNotificationPage = () => {
    const { users } = this.props;
    const { errors, formTarget, formContent, formSubject, formTitle, sendLoading } = this.state;

    return (
      <div style={styles.wrapper}>
        <div style={styles.contentWrapper}>
          <div styles={styles.titleWrapper}>
            <Typography variant="h3" component="h3" style={styles.pageTitle}>
              Notification center
            </Typography>
            <Paper elevation={3} style={styles.formWrapper}>
              <div style={styles.actionsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={
                    !(formTarget.length && formContent && formSubject && formTitle) ||
                    sendLoading
                  }
                  endIcon={<SendIcon />}
                  onClick={this.handleSend}
                  style={styles.sendButton}
                >
                  Send
                </Button>
              </div>
              <NotificationForm
                users={users}
                onSubjectChange={this.handleSubjectChange}
                onTitleChange={this.handleTitleChange}
                onContentChange={this.handleContentChange}
                onTargetChange={this.handleTargetChange}
                errors={errors}
              />
            </Paper>
          </div>
        </div>
      </div>
    );
  };

  handleSubjectChange = (newSubject) => {
    this.setState({ formSubject: newSubject });
  };

  handleTitleChange = (newTitle) => {
    this.setState({ formTitle: newTitle });
  };
  handleContentChange = (newContent) => {
    this.setState({ formContent: newContent });
  };

  handleTargetChange = (ids) => {
    this.setState({ formTarget: ids });
  };

  handleSend = () => {
    const { sendNotification } = this.props;
    const { formTarget, formContent, formSubject, formTitle } = this.state;

    this.setState({ sendLoading: true });

    sendNotification({
      subject: formSubject,
      title: formTitle,
      content: formContent,
      users: formTarget,
    }).then(() => {
      this.setState({ sendLoading: false });
    });
  };
}

function mapState(state) {
  const { items: users, loading } = state.users;

  return { users, loading };
}

const actionCreators = {
  usersFetch: UserActions.fetch,
  sendNotification: NotificationActions.sendNotification,
};

const connectedNotificationSenderPage = connect(mapState, actionCreators)(NotificationSender);
export default connectedNotificationSenderPage;