import React from 'react';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import { history } from "../../helpers";

import { PrismNavigator, ProfileMenu } from '../../components';

import { ROLES } from '../../utils/Enum';

import { UserActions } from '../../actions';

import styles from './Navbar.style';


class Navbar extends React.PureComponent {
  render() {
    const { navMap } = this.props;

    const initials = this.getUserInitials();
    const options = this.getOptions();

    return (
      <nav style={styles.wrapper}>
        <div style={styles.logoWrapper}>
          <PrismNavigator size={30} navMap={navMap} prismBorder disableTooltip />
          <a href='/'  style={styles.titleLink}>
            <Typography size={68} style={styles.title}>
              finance
            </Typography>
            <Typography size={68} style={{ ...styles.title, ...styles.secondTitleWord }}>
              cockpit
            </Typography>
          </a>
        </div>
        <ProfileMenu initials={initials} options={options} />
      </nav>
    );
  }

  handleLogout = () => {
    const { logout } = this.props;

    logout();
    history.push('/login');
  }

  handleAdminManage = () => {
    history.push('/admin/settings');
  }

  getUserInitials = () => {
    const { user } = this.props;

    try {
      const { firstName, lastName } = user;

      return `${firstName} ${lastName}`.split(' ').map((n) => n.toUpperCase()[0]).join('');
    } catch {
      return '';
    }
  }

  getOptions = () => {
    const { user } = this.props;

    const options = [
      { id: 'options-menu-logout', callback: this.handleLogout, name: 'Logout', icon: 'power-off'}
    ];

    if (user.roleId === ROLES.ADMIN)
      options.unshift({ id: 'options-menu-admin-manage', callback: this.handleAdminManage, name: 'Admin settings', icon: 'user-friends'})

    return options;
  }
}

function mapState(state) {
  const { loggingIn, navMap, user } = state.authentication;

  return { loggingIn, navMap, user };
}

const actionCreators = {
  logout: UserActions.logout
};

const connectedNavBar = connect(mapState, actionCreators)(Navbar);
export default connectedNavBar;