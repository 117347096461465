export default {
  wrapper: {
    position: 'relative',
    height: 68,
    backgroundColor: 'transparent',
  },
  logo: {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/ef-diamond-min.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: 48,
    width: 48,
    position: 'absolute',
    top: 10,
    left: 10,
  }
};
