import { reportConstants } from '../constants';

let initialState = {
  loading: true,
  reportUrl: '',
  reportName: '',
  error: '',
  adminEdition: {
    reports: [],
    fieldsDisabled: false,
  }
};

export function report(state = initialState, action) {
  switch (action.type) {
    case reportConstants.GET_URL_REQUEST: {
      return {
        ...state,
        loading: true,
        reportName: action.payload,
      };
    }
    case reportConstants.GET_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        reportUrl: action.payload,
      };
    }
    case reportConstants.GET_URL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case reportConstants.GET_ALL_REQUEST: {
      return {
        ...state,
        error: "",
        loading: true,
      };
    }

    case reportConstants.GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        adminEdition: {
          ...state.adminEdition,
          reports: action.payload,
        },
      };
    }

    case reportConstants.GET_ALL_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case reportConstants.SET_URL: {
      const { reports } = state.adminEdition;
      const { id, url } = action.payload;

      const newList = reports.reduce((acc, report) => {
        return [
          ...acc,
          id === report.id ? { ...report, url: url.trim()} : report,
        ];
      }, []);

      return {
        ...state,
        adminEdition: {
          ...state.adminEdition,
          reports: newList,
        },
      };
    }

    case reportConstants.SAVE_REQUEST: {
      return {
        ...state,
        error: '',
        adminEdition: {
          ...state.adminEdition,
          fieldsDisabled: true,
        },
      };
    }

    case reportConstants.SAVE_SUCCESS: {
      const { reports } = state.adminEdition;
      const { payload: reportUpdated } = action;

      const newList = reports.reduce((acc, report) => {
        return [
          ...acc,
          reportUpdated.id === report.id ? reportUpdated : report,
        ];
      }, []);

      return {
        ...state,
        adminEdition: {
          ...state.adminEdition,
          reports: newList,
          fieldsDisabled: false,
        },
      };
    }

    case reportConstants.SAVE_FAILURE: {
      return {
        ...state,
        error: action.payload,
        adminEdition: {
          ...state.adminEdition,
          fieldsDisabled: false,
        },
      };
    }

    default:
      return state;
  }
}