import { authHeader } from '../helpers';
import { REPORTS_TYPE, PRISM_PART } from '../utils/Enum';
import { buildBaseHeaders } from './request.service';

export default {
  login,
  loginWithGoogle,
  logout,
  checkActivationToken,
  activate,
  resendInvitation,
  isInvitationCouldBeResent,
  getById,
  create,
  update,
  getAll,
  getAuthorizedNavMap,
  delete: _delete,
};

const url = window.location.origin + '/api';

const RESEND_TIMEOUT = 15;

function isInvitationCouldBeResent(user) {
  if (!user || !user.lastInvitationDate) {
    return { isValid: true };
  }

  const lastInvitationDate = new Date(user.lastInvitationDate);
  const retryDate = new Date(lastInvitationDate.setMinutes(lastInvitationDate.getMinutes() + RESEND_TIMEOUT));

  if (retryDate > new Date()) {
    return { isValid: false, error: `You can't resend an invitation before ${RESEND_TIMEOUT} minutes of the last one.` };
  }

  return { isValid: true };
}

function checkActivationToken(token) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...buildBaseHeaders('PATCH'),
    },
    body: JSON.stringify({ token })
  };

  return fetch(`${url}/users/admin/checkToken`, requestOptions)
    .then(handleResponse);
}

function activate(token, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...buildBaseHeaders('POST'),
    },
    body: JSON.stringify({ token, password: password.trim() })
  };

  return fetch(`${url}/users/admin/activate`, requestOptions)
    .then(handleResponse);
}

function resendInvitation(userId) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...buildBaseHeaders('POST'),
    },
  };

  return fetch(`${url}/users/admin/${userId}/invitation`, requestOptions)
    .then(handleResponse);
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...buildBaseHeaders('POST'),
    },
    body: JSON.stringify({ email: email.trim(), password: password.trim() })
  };

  return fetch(`${url}/auth/login`, requestOptions)
    .then(handleResponse)
    .then(res => {
      const { payload: user } = res;
      const { token, refreshToken, ...rest } = user;

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('accessToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(rest));

      return user;
    });
}

function loginWithGoogle(code) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...buildBaseHeaders('POST'),
    },
    body: JSON.stringify({ code })
  };

  return fetch(`${url}/auth/login/google`, requestOptions)
    .then(handleResponse)
    .then(res => {
      const { payload: user } = res;
      const { token, refreshToken, ...rest } = user;

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('accessToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(rest));

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...buildBaseHeaders('GET'),
      ...authHeader(),
    },
  };

  return fetch(`${url}/users/${id}`, requestOptions).then(handleResponse);
}

function create(user) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...buildBaseHeaders('POST'),
      ...authHeader(),
    },
    body: JSON.stringify(user)
  };

  return fetch(`${url}/users/admin`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      ...buildBaseHeaders('PUT'),
      ...authHeader(),
    },
    body: JSON.stringify(user)
  };

  return fetch(`${url}/users/admin/${user.id}`, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...buildBaseHeaders('GET'),
      ...authHeader(),
    },
  };

  return fetch(`${url}/users/`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...buildBaseHeaders('DELETE'),
      ...authHeader(),
    },
  };

  return fetch(`${url}/users/admin/${id}`, requestOptions).then(handleResponse);
}

function getAuthorizedNavMap(user) {
  const {
    ASSETS,
    FINANCE_OVERVIEW,
    OD,
    ED,
    FOCUS_AREA,
    EF_ERS,
  } = REPORTS_TYPE;

  console.log(REPORTS_TYPE)

  return {
    [PRISM_PART.YELLOW]: { route: `/report/${ASSETS}`, disabled: false },
    [PRISM_PART.PINK]: { route: `/report/${FINANCE_OVERVIEW}`, disabled: false },
    [PRISM_PART.GREEN]: { route: `/report/${OD}`, disabled: false },
    [PRISM_PART.PURPLE]: { route: `/report/${ED}`, disabled: false },
    [PRISM_PART.BLUE]: { route: `/report/${FOCUS_AREA}`, disabled: false },
    [PRISM_PART.LIGHT_BLUE]: { route: `/report/${EF_ERS}`, disabled: false },
  };
}

function handleResponse(response) {
  return response.text().then(text => {
    let data = null;

    try {
      data = text && JSON.parse(text);
    } catch (error) {
      data = text;
    }

    if (!response.ok) {
      if (response.status === 401)
          // auto logout if 401 response returned from api
          logout();

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}