import { createTheme } from '@material-ui/core/styles';

import { COLORS } from '../foundation';

export default createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY.DEFAULT,
    },
    secondary: {
      main: COLORS.GREY_DARK.DEFAULT,
    },
  },
});