import { StyleSheet } from 'aphrodite';
import { COLORS, TYPOGRAPHY } from '../../../foundation';

const DRAWER_WIDTH = 56;

export default {
  drawer: {
    transition: 'width 350ms ease-in-out',
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    transition: 'width 350ms ease-in-out',
    width: DRAWER_WIDTH,
    top: 68,
    zIndex: 0,
  },
  optionItem: {
    whiteSpace: 'nowrap',
    paddingTop: 16,
    paddingBottom: 16,
  }
};

export const extendedStyles = StyleSheet.create({
  drawer: {
    ':hover': {
      width: DRAWER_WIDTH * 5,
    }
  },
  drawerPaper: {
    ':hover': {
      width: DRAWER_WIDTH * 5,
    }
  },
});
