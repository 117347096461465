import { COLORS } from '../../foundation';


export default {
  root: {
    width: '100%',
    minHeight: '101vh',
    position: 'relative',
    // backgroundImage: `url('${process.env.PUBLIC_URL}/images/home-wallpaper.jpg')`,
    // backgroundSize: 'cover',
    backgroundColor: COLORS.TRUE_BLACK.DEFAULT,
  },
  background: {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/ETH-Finance-Cockpit-Animation.gif')`,
    backgroundSize: 'cover',
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',
    zIndex: 2,
    backgroundPosition: '50% 50%',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontWeight: 'bold',
    color: COLORS.TEXT.SECONDARY_DEFAULT,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'absolute',
    zIndex: 3,
  },
  navigator: {
    alignSelf: 'flex-start',
    marginTop: 22,
    zIndex: 4,
  },
};
