
import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  CssBaseline,
  Paper,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';

import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { UserActions } from '../../actions';

import { Loader } from '../../components';

import { COLORS } from '../../foundation';

import styles from './AdminActivation.style';


const defaultErrors = {
  password: '',
  confirmPassword: '',
}

class AdminActivation extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      errors: {
        ...defaultErrors,
      },
      submitted: false
    };
  }

  componentDidMount() {
    const { match: { params }, checkAdminToken} = this.props;
    const { token } = params;

    return Promise.resolve()
      .then(() => new Promise(resolve => setTimeout(resolve, 800)))
      .then(() => checkAdminToken(token));
  }

  render() {
    return (
      <Grid container style={styles.root}>
        <CssBaseline />
        <div style={styles.backgroundLogo}>
          <div style={styles.overlay} />
        </div>
        {this.renderContent()}
      </Grid>
    );
  }

  renderContent = () => {
    const { loading } = this.props;
    const { activation: { isAuthorizedToken }} = this.props;

    if (loading) {
      return (
        <div style={styles.loaderWrapper}>
          <Loader />
        </div>
      );
    }

    const color = isAuthorizedToken ? COLORS.PRIMARY.DEFAULT : COLORS.ERROR.DEFAULT;

    const paperStyle = {
      ...styles.contentWrapper,
      borderTopColor: color,
    };

    return (
      <Paper elevation={3} style={paperStyle}>
        {this.renderActivationContent()}
      </Paper>
    );
  }

  renderActivationContent = () => {
    const { activation: { isAuthorizedToken }} = this.props;

    if (isAuthorizedToken)
      return this.renderActivationForm();

    return (
      <div style={styles.errorWrapper}>
        <div style={styles.errorContentWrapper}>
          <div style={styles.errorImage}/>
          <div style={styles.errorContent}>
            <Typography variant='h1' style={styles.title}>
              Activation link not registered
            </Typography>
            <Typography variant='h5' style={styles.description}>
              The activation link you used is not valid on this platform.<br />This
              kind of link are valid 24 hours after the creation of your account.<br />
              Please contact an administrator in order to resend you an invitation.
            </Typography>
          </div>
        </div>
        <div style={styles.actionWrapper}>
          <Button href="/login" style={styles.errorButton}>Return to login page</Button>
        </div>
      </div>
    );
  }

  renderActivationForm = () => {
    const { password, confirmPassword, submitted, errors } = this.state;

    return (
      <div>
        <div style={styles.headForm}>
          <VpnKeyIcon />
          <Typography component="h1" variant="h5" style={styles.headTitle}>
            You're almost ready
          </Typography>
        </div>
        <Typography component="h5">
          Activate your account by setting your password
        </Typography>
        <form style={styles.form} noValidate>
          <TextField
            error={!!errors.password}
            helperText={errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="password"
            autoFocus
            value={password}
            onChange={this.handleChange}
          />
          <TextField
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm password"
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={this.handleChange}
            autoComplete="password"
          />
          <div style={styles.buttonWrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={styles.submit}
              onClick={this.handleSubmit}
              disabled={(!password || !confirmPassword || submitted) }
            >
              Activate your account
            </Button>
          </div>
        </form>
      </div>
    );
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      const { password } = this.state;
      const { match: { params }, adminActivation, login } = this.props;
      const { token } = params;
      const newErrors = {
        password: '',
        confirmPassword: '',
      };

      if (this.validate())
        return adminActivation(token, password)
          .then(res => {
            if (res.error) {
              newErrors.password = res.error;
              newErrors.confirmPassword = res.error;

              return Promise.resolve();
            }

            return login(res.email, password);
          })
          .then(() => this.setState({ submitted: false, errors: newErrors }))

      this.setState({ submitted: false });
    });
  }

  validate = () => {
    const { password, confirmPassword } = this.state;
    const errors = {};
    let isValid = true;

    if (!(password === confirmPassword)) {
      isValid = false;
      errors.password = "Passwords mismatch.";
      errors.confirmPassword = "Passwords mismatch.";
    } else {
      if (!password) {
        isValid = false;
        errors.password = "Please enter your password.";
      } else {
        if (password.length < 8){
            isValid = false;
            errors.password = 'A password should at least 8 characters long.';
        }
      }

      if (!confirmPassword) {
        isValid = false;
        errors.confirmPassword = "Please confirm your password.";
      } else {
        if (confirmPassword.length < 8){
            isValid = false;
            errors.confirmPassword = 'A password should at least 8 characters long.';
        }
      }
    }

    this.setState({ errors: errors });

    return isValid;
  }
}

function mapState(state) {
  const { activation, loading } = state.users;

  return { activation, loading };
}

const actionCreators = {
  adminActivation: UserActions.activate,
  checkAdminToken: UserActions.checkToken,
  login: UserActions.login,
};

const connectedActivationPage = connect(mapState, actionCreators)(AdminActivation);
export default connectedActivationPage;