import { TYPOGRAPHY, COLORS } from '../../foundation';

export default {
  wrapper: {
    display: 'flex',
    flex: 1,
    height: 31,
    width: '100%',
  },
  globalContainer: {
    display: 'flex',
    flexGrow: 2,
    height: 31,
    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  containerText: {
    display: 'flex',
    marginLeft: 10,
    alignItems: 'center',
    paddingRight: 24,
    width: '100%',
  },
  containerSelected: {
    marginRight: 10,
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
  },
};