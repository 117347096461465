import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from '../reducers';

const loggerMiddleware = createLogger();

let storeValue = null;

if (process.env.NODE_ENV === 'development') {
    storeValue = createStore(
        rootReducer,
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )
    );
} else
    storeValue = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export const store = storeValue;