import { passwordConstants } from '../constants';
import { passwordService } from '../services';
import { AlertActions } from './';

export default {
    askNew,
    update,
};

function askNew(email) {
    return dispatch => {
        dispatch(request());

        return passwordService.askNewForEmail(email)
            .then(
                res => {
                    dispatch(success());
                    dispatch(AlertActions.success(res.message));
                },
                error => {
                    dispatch(failure());
                    dispatch(AlertActions.error(error.message));
                }
            );
    };

    function request() { return { type: passwordConstants.ASK_NEW_REQUEST } }
    function success() { return { type: passwordConstants.ASK_NEW_SUCCESS } }
    function failure() { return { type: passwordConstants.ASK_NEW_FAILURE } }
}

function update(token, password) {
    return dispatch => {
        return passwordService.update(token, password)
            .then(
                res => {
                    dispatch(AlertActions.success(res.message));

                    return res.payload
                },
                error => {
                    dispatch(AlertActions.error(error.message));

                    return { error };
                }
            );
    };
}
