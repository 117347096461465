
import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  CssBaseline,
} from '@material-ui/core';

import { history } from '../../helpers';

import { BackgroundVideo, HomeNavBar } from '../../components';
import { PRISM_PART } from '../../utils/Enum';

import styles from './Home.style';

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedItemMap: {
        [PRISM_PART.YELLOW]: false,
        [PRISM_PART.PINK]: false,
        [PRISM_PART.PURPLE]: false,
        [PRISM_PART.GREEN]: false,
        [PRISM_PART.BLUE]: false,
        [PRISM_PART.LIGHT_BLUE]: false,
      },
      prismSelectedItem: '',
    };
  }

  render() {
    const { selectedItemMap } = this.state;

    let selectedItem = null

    for (const [key, val] of Object.entries(selectedItemMap)) {
      if (val)
        selectedItem = key;
    }

    const url = [
      `${process.env.PUBLIC_URL}/videos/video2.mp4`
    ];

    return (
      <Grid container style={styles.root}>
        <CssBaseline />
        <div style={styles.background} />
        <div style={styles.backgroundVideoWrapper}>
          <BackgroundVideo url={url} />
        </div>
        <Grid container style={styles.wrapper}>
          <HomeNavBar
            selectedNavItem={selectedItem}
            onItemHover={this.handleHoverItem}
            onItemClick={this.handleItemClick}
          />
        </Grid>
      </Grid>
    );
  }

  // handleSelectNavItem = (part, isHover) => {
  //   const { selectedItemMap } = this.state;

  //   selectedItemMap[part] = isHover;

  //   this.setState({ selectedItemMap })
  // }

  handleHoverItem = (name, isHover) => {
    const { prismSelectedItem } = this.state;

    let newSelectedItem = '';

    if (isHover && !prismSelectedItem)
      newSelectedItem = name;

    this.setState({ prismSelectedItem: newSelectedItem })
  }

  handleItemClick = (prismName) => {
    const { navMap } = this.props;
    const config = navMap[prismName];

    console.log(config);

    if (!config.disabled)
      history.push(config.route);
  }
}

function mapState(state) {
  const { loggingIn, navMap } = state.authentication;

  return { loggingIn, navMap };
}

const connectedHomePage = connect(mapState, {})(Home);
export default connectedHomePage;