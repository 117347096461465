import { adminModalConstants } from '../constants';
import { roleService } from '../services';

export default {
  open,
  close,
  setFirstName,
  setLastName,
  setEmail,
  setRole,
  fetchRoles,
};

function open(user = null) {
  return { type: adminModalConstants.OPEN, user }
}

function close() {
  return { type: adminModalConstants.CLOSE }
}

function setFirstName(firstName) {
  return { type: adminModalConstants.SET_FIRSTNAME, firstName }
}

function setLastName(lastName) {
  return { type: adminModalConstants.SET_LASTNAME, lastName }
}

function setEmail(email) {
  return { type: adminModalConstants.SET_EMAIL, email }
}

function setRole(roleId) {
  return { type: adminModalConstants.SET_ROLE, roleId }
}

function fetchRoles() {
    return dispatch => {
        dispatch(request());

        roleService.getAll()
            .then(
                res => {
                    const roles = res.payload;

                    dispatch(success(roles));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminModalConstants.FETCH_ROLES_REQUEST } }
    function success(roles) { return { type: adminModalConstants.FETCH_ROLES_SUCCESS, roles } }
    function failure(error) { return { type: adminModalConstants.FETCH_ROLES_FAILURE, error } }
}
