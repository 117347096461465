import { passwordConstants } from '../constants';
import { userService } from '../services';

const initialState = {
  emailSending: false,
};

export function password(state = initialState, action) {
    switch (action.type) {
      case passwordConstants.ASK_NEW_REQUEST: {
        return {
          ...state,
          emailSending: true,
        };
      }

      case passwordConstants.ASK_NEW_SUCCESS:
      case passwordConstants.ASK_NEW_FAILURE: {
        return {
          ...state,
          emailSending: false
        };
      }

      // case passwordConstants.UPDATE_REQUEST: {
      //   return {
      //     ...state,
      //     passwordUpdating: true,
      //   };
      // }

      // case passwordConstants.UPDATE_SUCCESS:
      // case passwordConstants.UPDATE_FAILURE: {
      //   return {
      //     ...state,
      //     passwordUpdating: false
      //   };
      // }

      default:
          return state
    }
}