import React from 'react';
import { CircularProgress } from '@material-ui/core';

import styles from './Loader.style';


export default class Loader extends React.PureComponent {
  render() {
    return (
      <div style={styles.wrapper}>
        <CircularProgress color='primary' size={68} />
        <div style={styles.logo} />
      </div>
    );
  }
}