/* eslint react/default-props-match-prop-types: ["error", { "allowRequiredDefaults": true }] */
// @flow

/**
 * Item use in a Dropdown box
 *
 * Props:
 *  - id: an id to the item
 *  - text: text inside the item
 *  - selected: true if the item is selected
 *  - indicatorText: text of the indicator
 *  - onClick: called when click on the item
 *  - style: override component's style
 *  - color: color of the text
 *  - onHover: function called on component hover
 *  - removeMouseFocus: remove the mouse focus on the component (remove cursor and focus's style)
 */

import * as React from 'react';
import {
  Checkbox,
} from '@material-ui/core';

import { TYPOGRAPHY, COLORS } from '../../foundation';

import styles from './DropdownItem.style';


export class DropdownItem extends React.Component {
  static defaultProps = {
    id: 0,
    color: COLORS.TEXT.DEFAULT,
    selected: false,
    isCheckBoxDisplayed: true,
    style: undefined,
    text: '',
    onClick: () => { },
    onHover: () => { },
    removeMouseFocus: false,
  };

  state = {
    isHover: false,
  };

  // Performance
  shouldComponentUpdate(nextProps, nextState) {
    const copyOldProps = JSON.stringify(this.props);
    const copyNextProps = JSON.stringify(nextProps);

    if (copyOldProps === copyNextProps && nextState === this.state)
      return false;

    return true;
  }

  render() {
    const { style: propStyle, removeMouseFocus } = this.props;

    const backgroundColor = this.getBackgroundColor();

    let cursor = 'pointer';

    if (removeMouseFocus)
      cursor = 'none';

    const style = {
      backgroundColor,
      cursor,
    };

    return (
      <div
        data-testid='dropdown-item'
        style={propStyle}
      >
        <div style={styles.wrapper}>
          <div
            onClick={this.handleClick}
            onMouseEnter={this.handleHover}
            onMouseLeave={this.handleLeave}
            style={{ ...styles.globalContainer, ...style }}
          >
            {this.renderItem()}
          </div>
        </div>
      </div>
    );
  }

  renderItem = () => {
    return (
      <>
        { this.renderSelected() }
        { this.renderContainerText() }
      </>
    );
  }

  renderContainerText = () => {
    const { text, color, itemIndication } = this.props;
    const wrapperStyle = {
      ...styles.containerText,
      justifyContent: itemIndication ? 'space-between' : 'initial',
    };
    const styleText = {
      ...TYPOGRAPHY.PS,
      color,
    };

    return (
      <div style={styles.containerText}>
        <div style={wrapperStyle}>
          <div style={styleText}>
            {text}
          </div>
        </div>
      </div>
    );
  }

  renderSelected = () => {
    const { selected, isCheckBoxDisplayed } = this.props;

    if (!isCheckBoxDisplayed)
      return null;

    return (
      <Checkbox
        checked={selected}
      />
    );
  }

  handleClick = (e) => {
    const { onClick, id } = this.props;

    e.stopPropagation();

    if (onClick)
      onClick(id);
  }

  handleHover = () => {
    const { onHover, id } = this.props;

    this.setState({ isHover: true }, () => onHover(id));
  }

  handleLeave = () => {
    this.setState({ isHover: false });
  }

  getBackgroundColor = () => {
    const { selected, removeMouseFocus } = this.props;
    const { isHover } = this.state;

    if (removeMouseFocus)
      return 'transparent';

    return selected
      ? COLORS.GREY_MEDIUM.DEFAULT
      : isHover
        ? COLORS.WHITE.HOVER
        : 'transparent';
  }
}

export default DropdownItem;