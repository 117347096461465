import { reportConstants } from '../constants';
import { reportService } from '../services';
import { AlertActions } from './';

export default {
    getUrl,
    getAll,
    update,
    setUrl,
};

function getUrl(reportName) {
    return dispatch => {
        dispatch(request(reportName));

        return reportService.getReportUrl(reportName)
            .then(
                res => {
                    dispatch(success(res.payload));
                },
                error => dispatch(failure(error))
            );
    };

    function request(reportName) { return { type: reportConstants.GET_URL_REQUEST, payload: reportName } }
    function success({ url }) { return { type: reportConstants.GET_URL_SUCCESS, payload: url } }
    function failure(error) { return { type: reportConstants.GET_URL_FAILURE, payload: error } }
}

function getAll() {
    return (dispatch) => {
        dispatch(request());

        return reportService.getAllReports().then(
        (res) => {
            dispatch(success(res.payload.reports));
        },
        (error) => dispatch(failure(error))
        );
    };

    function request() {
        return { type: reportConstants.GET_ALL_REQUEST };
    }
    function success(reports) {
        return { type: reportConstants.GET_ALL_SUCCESS, payload: reports };
    }
    function failure(error) {
        return { type: reportConstants.GET_ALL_FAILURE, payload: error };
    }
}

function setUrl(id, url) {
    return { type: reportConstants.SET_URL, payload: { id, url } };
}

function update(report) {
    return (dispatch) => {
        dispatch(request());

        return reportService.update(report).then(
        (res) => {
            dispatch(success(res.payload));
        },
        (error) => dispatch(failure(error))
        );
    };

    function request() {
        return { type: reportConstants.SAVE_REQUEST };
    }
    function success(report) {
        return { type: reportConstants.SAVE_SUCCESS, payload: report };
    }
    function failure(error) {
        return { type: reportConstants.SAVE_FAILURE, payload: error };
    }
}
