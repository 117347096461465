import React from 'react';
import { css } from 'aphrodite';

import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Icon,
  Tooltip,
  Chip,
  IconButton
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Send as ResendIcon
} from '@material-ui/icons';

import { COLORS } from '../../../../../../foundation';

import styles, { extendedStyles } from './AdminItem.style';


export default class AdminItem extends React.PureComponent {
  state = {
    deleteButtonHovered: false,
    editButtonHovered: false,
    resendButtonHovered: false,
  };

  constructor(props) {
    super(props);

    this._timeOut = null;
  }

  componentWillUnmount() {
    if (this._timeOut)
      clearTimeout(this._timeOut);
  }

  render() {
    const { name, email, role } = this.props;

    return (
      <Card style={styles.wrapper}>
        <CardContent style={styles.cardContent}>
          <div styles={styles.nameWrapper}>
            <Typography variant="h5" component="h2" style={styles.name}>
              { name }
            </Typography>
            <Typography style={styles.email}>
              { email }
            </Typography>
          </div>
          {this.renderStatus()}
        </CardContent>
        <CardActions style={styles.cardActions}>
          <Chip
            label={role}
            icon={<Icon className='fa fa-user-tag' style={styles.indicatorIcon} />}
            style={styles.roleIndicator}
          />
          { this.renderButtonContainer() }
        </CardActions>
      </Card>
    );
  }

  renderButtonContainer = () => {
    const { onEdit, onDelete } = this.props;
    const { deleteButtonHovered, editButtonHovered } = this.state;

    const editButtonStyle = {
      ...styles.button,
      ...styles.editButton,
    };

    const deleteButtonStyle = {
      ...styles.button,
      ...styles.deleteButton,
    };

    return (
      <div style={styles.buttonWrapper}>
        <Button
          variant="outlined"
          size="small"
          startIcon={<EditIcon style={{ ...styles.buttonIcon, ...(editButtonHovered ? styles.buttonIconHover : {})}} />}
          onClick={onEdit}
          onMouseEnter={this.handleHover.bind(null, 'edit', true)}
          onMouseLeave={this.handleHover.bind(null, 'edit', false)}
          style={editButtonStyle}
          className={css(extendedStyles.editButtonHovered)}
        >
          {editButtonHovered ? 'Edit' : ''}
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<DeleteIcon style={{ ...styles.buttonIcon, ...(deleteButtonHovered ? styles.buttonIconHover : {}) }} />}
          onClick={onDelete}
          onMouseEnter={this.handleHover.bind(null, 'delete', true)}
          onMouseLeave={this.handleHover.bind(null, 'delete', false)}
          style={deleteButtonStyle}
          className={css(extendedStyles.deleteButtonHovered)}
        >
          {deleteButtonHovered ? 'Delete' : ''}
        </Button>
      </div>
    )
  }

  renderStatus = () => {
    const { verified, onResendInvite, canResendInvite, resendImpossibleMessage } = this.props;
    const { resendButtonHovered } = this.state;

    const iconName = verified ? 'check' : 'hourglass-half';
    const iconColor = verified ? COLORS.SUCCESS.DEFAULT : COLORS.WARNING.DEFAULT;
    const verifiedHelp = verified ? 'verified' : 'in verification';

    const wrapperStyle = {
      ...styles.statusWrapper,
      borderColor: iconColor,
    };

    const iconStyle = {
      ...styles.status,
      color: iconColor,
    };

    const resendButtonStyle = {
      ...styles.button,
      ...styles.resendButton,
      ...(!canResendInvite ? styles.resendButtonDisabled : {}),
    };

    return (
      <div
        onMouseEnter={this.handleHover.bind(null, 'resend', true)}
        onMouseLeave={this.handleHover.bind(null, 'resend', false)}
        style={styles.statusContainer}
      >
        <Tooltip title={`User ${verifiedHelp}`}>
          <div style={wrapperStyle}>
            <Icon className={`fa fa-${iconName}`} style={iconStyle}/>
          </div>
        </Tooltip>
        {
          !verified && resendButtonHovered ? (
            <Tooltip title={ !canResendInvite ? resendImpossibleMessage : 'Resend invitation link' }>
              <span>
                <IconButton
                  variant="outlined"
                  size="small"
                  startIcon={<ResendIcon style={{ ...styles.buttonIcon, ...(resendButtonHovered ? styles.buttonIconHover : {})}} />}
                  onClick={onResendInvite}
                  disabled={!canResendInvite}
                  style={resendButtonStyle}
                >
                  <ResendIcon />
                </IconButton>
              </span>
            </Tooltip>
          )
          : null
        }
      </div>
    );
  }

  handleHover = (button, isHover) => {
    clearTimeout(this._timeOut);

    if (isHover) {
      this._timeOut = setTimeout(() => {
        this.setState({ [`${button}ButtonHovered`]: isHover });
      }, 100);
    } else
      this.setState({ [`${button}ButtonHovered`]: isHover });
  }
}
