
import React from 'react';
import IframeResizer from 'iframe-resizer-react'

import { connect } from 'react-redux';

import { history } from "../../helpers";
import { ReportActions } from '../../actions';

import { Navbar, Loader } from '../../components';

import {
  Grid,
  CssBaseline,
} from '@material-ui/core';

import styles from './ReportView.style';

class ReportView extends React.Component {
  constructor() {
    super();

    console.log('hey')

    this.iframeRef = React.createRef();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { getReportUrl } = this.props;
    const route = history.location.pathname.split('/').pop();

    return getReportUrl(route);
  }

  componentDidUpdate(prevProps) {
    const { getReportUrl, reportName } = this.props;
    const route = history.location.pathname.split('/').pop();

    if (reportName !== route)
      getReportUrl(route);
  }

  render() {
    const { reportUrl } = this.props;

    return (
      <Grid style={styles.root}>
        <CssBaseline />
        {this.renderLoader()}
        <Navbar />
        <IframeResizer
          forwardRef={this.iframeRef}
          heightCalculationMethod="bodyScroll"
          inPageLinks
          log
          src={reportUrl}
          style={{ width: '1px', minWidth: '100%', border: 0, marginTop: 68 }}
          allowFullScreen
          warningTimeout={10}
          onLoad={this.onIframeLoad}
        />
      </Grid>
    );
  }

  renderLoader = () => {
    const { loading } = this.props;
    const { isLoading } = this.state;

    if (!(isLoading || loading))
      return null;

    return (
      <div style={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  onIframeLoad = () => {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 3000);
  }
}

function mapState(state) {
  const { loading, reportUrl, reportName } = state.report;

  return { loading, reportUrl, reportName };
}

const actionCreators = {
  getReportUrl: ReportActions.getUrl,
};

const connectedReportViewPage = connect(mapState, actionCreators)(ReportView);
export default connectedReportViewPage;