import { notificationConstants } from '../constants';
import { notificationService } from '../services';
import { AlertActions } from './';

export default {
  sendNotification,
};

function sendNotification(notification) {
  return dispatch => {
    dispatch(AlertActions.clear());
    dispatch(request());

    return notificationService.sendNotification(notification)
      .then(
        res => {
          dispatch(success(res));
          dispatch(AlertActions.success(res.message));
        },
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: notificationConstants.SEND_NOTIF_REQUEST } }
  function success(res) { return { type: notificationConstants.SEND_NOTIF_SUCCESS, notification: res.payload } }
  function failure(error) { return { type: notificationConstants.SEND_NOTIF_FAILURE, error } }
}
