import { TYPOGRAPHY } from '../../../../../../foundation';


export default {
  wrapper: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  mailToContainer: {
  },
  targetLabel: {
    ...TYPOGRAPHY.H2,
    fontWeight: 'bold',
  },
  userSelect: {
    marginTop: 12,
  },
  allUsersOption: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
    marginLeft: -11,
  },
  allUserLabel: {
    ...TYPOGRAPHY.PR,
  },
  mailToText: {
    ...TYPOGRAPHY.PR,
    fontWeight: 'bold',
  },
  mailToChip: {
    marginLeft: 8,
  },
  formContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginTop: 16,
  },
  multiLineInput: {
    minHeight: 250,
  }
};
