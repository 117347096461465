export const reportConstants = {
  GET_URL_REQUEST: "REPORTS_GET_URL_REQUEST",
  GET_URL_SUCCESS: "REPORTS_GET_URL_SUCCESS",
  GET_URL_FAILURE: "REPORTS_GET_URL_FAILURE",
  GET_ALL_REQUEST: "REPORTS_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "REPORTS_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "REPORTS_GET_ALL_FAILURE",
  SAVE_REQUEST: "REPORTS_SAVE_REQUEST",
  SAVE_SUCCESS: "REPORTS_SAVE_SUCCESS",
  SAVE_FAILURE: "REPORTS_SAVE_FAILURE",
  SET_URL: "REPORTS_SET_URL",
};
