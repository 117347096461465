import { COLORS } from '../../foundation';
import { StyleSheet } from 'aphrodite';

export default {
  avatar: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: 30,
    fontWeight: 'bold',
    width: 50,
    height: 50,
    position: 'relative',
  },
  outline: {
    backgroundColor: 'transparent',
    borderColor: COLORS.WHITE.DEFAULT,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  opened: {
    backgroundColor: COLORS.WHITE.DEFAULT,
    color: COLORS.TEXT.DEFAULT,
  },
  initials: {
    paddingTop: 2,
  },
  menuContainer: {
    position: 'relative',
  },
  listContainer: {
    marginTop: 10,
  },
  optionIcon: {
    color: COLORS.BLACK.DEFAULT,
    fontSize: 12,
    marginRight: 16,
    width: 15,
  }
};

export const extendedStyles = StyleSheet.create({
  avatar: {
    ':hover': {
      transition: 'all 250ms ease-in-out',
      backgroundColor: COLORS.WHITE.DEFAULT,
      color: COLORS.TEXT.DEFAULT,
    },
    ':focus': {
      transition: 'all 250ms ease-in-out',
      backgroundColor: COLORS.WHITE.DEFAULT,
      color: COLORS.TEXT.DEFAULT,
    },
  },
});
