// @flow

import { COLORS } from './';

export default {
  HERO_TITLE: {
    fontWeight: '100',
    fontSize: '2em',
    color: COLORS.WHITE.DEFAULT,
    fontFamily: 'Genera-SemiBold',
  },
  HERO_TITLE_SM: {
    fontWeight: '100',
    fontSize: '22px',
    color: COLORS.WHITE.DEFAULT,
    fontFamily: 'Genera-SemiBold',
  },
  HERO_TITLE_XS: {
    fontWeight: '100',
    fontSize: '18px',
    color: COLORS.WHITE.DEFAULT,
    fontFamily: 'Genera-SemiBold',
  },
  HERO: {
    fontFamily: 'Roboto',
    fontSize: '28px',
    lineHeight: 'normal',
    color: COLORS.TEXT.DEFAULT,
  },
  H1: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  H2: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: 'normal',
  },
  PR: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  PS: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: 'normal',
  },
  CAPTION: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    lineHeight: 'normal',
  },
};
