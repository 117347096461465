import { COLORS } from '../../foundation';

export default {
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
    flex: 1,
  },
  prismNavigatorWrapper: {
    position: 'absolute',
    display: 'flex',
    marginLeft: 10,
    height: 88,
    alignItems: 'center',
    justifyContent: 'flex-start',
    userSelect: 'none',
  },
  loaderWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: COLORS.WHITE.DEFAULT,
    zIndex: 100,
  },
};
