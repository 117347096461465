import { StyleSheet } from 'aphrodite';

import { TYPOGRAPHY, COLORS } from '../../foundation';

export default {
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  headerContainer: {
    width: '100%',
    minWidth: '30px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GREY_DARK.DEFAULT,
    cursor: 'pointer',
  },
  headerContainerType: {
    small: {
      height: 30,
    },
    large: {
      height: 40,
    },
  },
  headerContent: {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    ...TYPOGRAPHY.PR,
    flex: 1,
    backgroundColor: 'transparent',
  },
  headerContentType: {
    small: {
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 11,
    },
    large: {
      paddingTop: 11,
      paddingBottom: 11,
      paddingLeft: 15,
    },
  },
  focusedHeader: {
    borderColor: COLORS.GREY_DARK.OUTLINE_FOCUSED,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
  },
  itemBox: {
    width: '100%',
    borderRadius: 4,
    padding: 0,
    margin: 0,
    marginTop: 4,
    position: 'absolute',
    zIndex: 999,
    top: '100%',
    display: 'block',
    border: `1px solid ${COLORS.GREY_MEDIUM.DEFAULT}`,
    backgroundColor: COLORS.WHITE.DEFAULT,
    maxHeight: 300,
    overflow: 'auto',
    outline: 'none',
  },
  item: {
    height: 31,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  itemLabel: {
    ...TYPOGRAPHY.PS,
    color: COLORS.TEXT.DEFAULT,
    paddingLeft: 10,
  },
  itemActive: {
    backgroundColor: COLORS.GREY_MEDIUM.HOVER,
  },
  dropdown: {
    position: 'relative',
  },
  dropdownItem: {
    position: 'absolute',
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    zIndex: 1,
    opacity: 0.2,
  },
  notFound: {
    ...TYPOGRAPHY.CAPTION,
    color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    fontWeight: 400,
    marginLeft: 12,
  },
  label: {
    ...TYPOGRAPHY.PS,
    color: COLORS.TEXT.DEFAULT,
    fontWeight: 700,
    position: 'absolute',
    top: -23,
    left: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '70%',
  },
};

export const extendedStyles = StyleSheet.create({
  input: {
    '::-webkit-input-placeholder': {
      color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    },
    ':-moz-placeholder': {
      color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    },
    '::-moz-placeholder': {
      color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    },
    ':-ms-input-placeholder': {
      color: COLORS.TEXT.PLACEHOLDER_DEFAULT,
    },
  },
});