import { COLORS, TYPOGRAPHY } from '../../../../foundation';


export default {
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
    flex: 1,
  },
  wrapper: {
    width: '100%',
  },
  contentWrapper: {
    marginTop: 72,
    padding: 30,
    width: '100%',
  },
  loaderWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: COLORS.WHITE.DEFAULT,
    zIndex: 100,
  },
  titleWrapper: {
    marginTop: 10,
  },
  pageTitle: {
    ...TYPOGRAPHY.HERO,
    fontWeight: 'bold',
    padding: 5,
  },
  formWrapper: {
    marginTop: 24,
    padding: 24,
  },
  field: {
    width: '100%',
  }
};
