import { jwtDecode } from 'jwt-decode'
import moment from 'moment'

import { ROLES } from '../utils/Enum';
import { routesRulesService } from '../services';

export function authHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user'));

  const act = localStorage.getItem('accessToken');
  const rt =  localStorage.getItem('refreshToken');

  const tokensHasExpired = tokenHasExpired();

  if (user.id && !tokensHasExpired) {
    return { 'Cookie': `__act=${act};__rt=${rt}`};
  } else {
    return {};
  }
}

export function tokenHasExpired() {
  const act = localStorage.getItem('accessToken');
  const rt =  localStorage.getItem('refreshToken');

  if (!act || !rt)
    return true;

  const actExpiration = jwtDecode(act).exp;
  const rtExpiration = jwtDecode(act).exp;

  const actExpired = moment.unix(actExpiration) < moment.utc(Date.now());
  const rtExpired = moment.unix(rtExpiration) < moment.utc(Date.now());

  return actExpired || rtExpired;
}

export function userHasRight(permission) {
  const user = JSON.parse(localStorage.getItem('user'));

  const role = user.roleId || ROLES.UNAUTHENTICATED;

  return routesRulesService.roleMatchRoute(role, permission)
}