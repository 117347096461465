import React, { useEffect } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import theme from '../theme/theme';
import { history } from '../helpers';
import { AlertActions, UserActions } from '../actions';
import { PrivateRoute, SessionTimeout } from '../components';
import {
    LoginPage,
    HomePage,
    ReportView,
    AdminSettings,
    AdminActivation,
    AdminPasswordReset,
    AdminAskNewPassword,
} from '../pages';

import { REPORTS_TYPE } from '../utils/Enum';

import styles from './App.style';

const App = ({
    alert,
    isAuthenticated,
    logout,
    clearAlerts
}) => {
    useEffect(() => {
        history.listen((location, action) => {
            if (alert.message) {
                // clear alert on location change
                clearAlerts();
            }
        });
    });

    const { ASSETS, FINANCE_OVERVIEW, OD, ED, FOCUS_AREA, EF_ERS } = REPORTS_TYPE;

    console.log({ alert })

    return (
        <MuiThemeProvider theme={theme}>
            <Grid container component="main">
                <div style={styles.alertContainer}>
                    {
                        alert.message
                        && <Alert severity={alert.type}>
                            { alert.message }
                        </Alert>
                    }
                </div>
                <Router history={history}>
                    <Switch>
                        <PrivateRoute exact path={`/report/${ASSETS}`} permission={`reports:${ASSETS}`} component={ReportView} />
                        <PrivateRoute exact path={`/report/${FINANCE_OVERVIEW}`} permission={`reports:${FINANCE_OVERVIEW}`} component={ReportView} />
                        <PrivateRoute exact path={`/report/${OD}`} permission={`reports:${OD}`} component={ReportView} />
                        <PrivateRoute exact path={`/report/${ED}`} permission={`reports:${ED}`} component={ReportView} />
                        <PrivateRoute exact path={`/report/${FOCUS_AREA}`} permission={`reports:${FOCUS_AREA}`} component={ReportView} />
                        <PrivateRoute exact path={`/report/${EF_ERS}`} permission={`reports:${EF_ERS}`} component={ReportView} />
                        <PrivateRoute exact path="/" permission='dashboard:home' component={HomePage} />
                        <PrivateRoute exact path="/admin/settings" permission='admin:settings' component={AdminSettings} />
                        <Route path="/activate/:token" component={AdminActivation} />
                        <Route path="/user/credentials/:token" component={AdminPasswordReset} />
                        <Route path="/user/new/passsword" component={AdminAskNewPassword} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="*" render={() => <Redirect to="/" />} />
                    </Switch>
                </Router>
                <SessionTimeout isAuthenticated={isAuthenticated} logout={logout} />
            </Grid>
        </MuiThemeProvider>
    );
}

function mapState(state) {
    const { alert, authentication } = state;

    return { alert, isAuthenticated: authentication.loggedIn };
}

const actionCreators = {
    clearAlerts: AlertActions.clear,
    logout: UserActions.logout,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };