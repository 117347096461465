import { userConstants } from '../constants';


const initialState = {
  loading: true,
  items: [],
  error: {
    message: '',
  },
  activation: {
    isAuthorizedToken: false,
    errorMessage: '',
  },
};

export function users(state = {...initialState}, action) {
  switch (action.type) {
    case userConstants.VERIFY_ACTIVATE_REQUEST: {
      return {
        ...state,
        loading: true,
        activation: {
          isAuthorizedToken: false,
          errorMessage: '',
        },
      };
    }
    case userConstants.VERIFY_ACTIVATE_SUCCESS: {
      const { activation } = state;
      const { isAuthorizedToken } = action;

      return {
        ...state,
        loading: false,
        activation: {
          ...activation,
          isAuthorizedToken,
        },
      };
    }
    case userConstants.VERIFY_ACTIVATE_FAILURE: {
      return {
        ...state,
        loading: false,
        activation: {
          isAuthorizedToken: false,
          errorMessage: action.error,
        },
      };
    }
    case userConstants.ACTIVATE_REQUEST: {
      const { activation } = state;

      return {
        ...state,
        activation: {
          ...activation,
          errorMessage: '',
        }
      };
    }
    case userConstants.ACTIVATE_FAILURE: {
      const { activation } = state;

      return {
        ...state,
        activation: {
          ...activation,
          errorMessage: action.error,
        }
      };
    }
    case userConstants.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: { message: '' },
      };
    case userConstants.FETCH_SUCCESS:
      return {
        ...state,
        items: action.users.payload,
        loading: false,
      };
    case userConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.DELETE_SUCCESS: {
      const id = parseInt(action.id)

      return {
        ...state,
        items: state.items.filter(user => user.id !== id)
      };
    }
    case userConstants.CREATE_REQUEST:
      return {
        ...state,
        error: { message: '' },
      };
    case userConstants.CREATE_SUCCESS: {
      const { items } = state;

      const newItems = [
        ...items,
        action.user,
      ];

      return {
        ...state,
        items: newItems,
      };
    }
    case userConstants.CREATE_FAILURE:
      return {
        ...state,
        error: { message: action.error }
      };
    case userConstants.RESEND_INVITATION_SUCCESS: {
      const { items } = state;
      const { id: updatedId, ...updatedUser } = action;

      const newItems = items.map((user) => {
        if (user.id === updatedId)
          return { ...user, ...updatedUser };

        return user;
      });

      return {
        ...state,
        items: newItems,
      };
    }
    case userConstants.SAVE_REQUEST:
      return {
        ...state,
        error: { message: '' },
      };
    case userConstants.SAVE_SUCCESS: {
      const { items } = state;
      const { user: updatedUser } = action;

      const newItems = items.map((user) => {
        if (user.id === updatedUser.id)
          return { ...user, ...updatedUser };

        return user;
      });

      return {
        ...state,
        items: newItems,
      };
    }
    case userConstants.SAVE_FAILURE:
      return {
        ...state,
        error: { message: action.error }
      };
    default:
      return state
  }
}