export const REPORTS_TYPE = Object.freeze({
  ASSETS: 'assets',
  FINANCE_OVERVIEW: 'financial-overview',
  OD: 'od',
  ED: 'ed',
  FOCUS_AREA: 'focus-area',
  EF_ERS: 'ef-ers',
});

export const PRISM_PART = Object.freeze({
  YELLOW: 'prism-yellow',
  PINK: 'prism-pink',
  GREEN: 'prism-green',
  PURPLE: 'prism-purple',
  BLUE: 'prism-blue',
  LIGHT_BLUE: 'prism-light-blue',
});

export const ROLES = {
  UNAUTHENTICATED: 0,
  ADMIN          : 1,
  LEVEL0         : 2,
  LEVEL1         : 3,
  LEVEL2         : 4,
};
