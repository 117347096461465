import { StyleSheet } from 'aphrodite';

import { COLORS, TYPOGRAPHY } from '../../foundation';
import { PRISM_PART } from '../../utils/Enum';

const { YELLOW, PINK, PURPLE, GREEN, BLUE, LIGHT_BLUE } = PRISM_PART;

export default {
  wrapper: {
    height: 64,
    width: '100%',
    position: 'fixed',
    top: 0,
  },
  nav: {
    height: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  subNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 15,
    marginRight: 15,
    width: '100%',
    paddingLeft: 100,
    paddingRight: 100,
  },
  itemsWrapper: {
    display: 'flex',
  },
  profileMenuContainer: {
    position: 'absolute',
    right: 10,
  },
  itemContainer: {
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    marginTop: 10,
  },
  itemWrapper:{
    justifyContent: 'center',
  },
  itemName: {
    ...TYPOGRAPHY.PR,
    fontFamily: 'Genera-SemiBold',
    fontWeight: 'bold',
    color: COLORS.WHITE.DEFAULT,
    borderBottomSize: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.TEXT.SECONDARY_DEFAULT,
    textAlign: 'center',
  },
};

export const extendedStyles = StyleSheet.create({
  profileMenuContainer: {
    '@media (max-width: 600px)': {
      right: 0,
    },
    '@media (max-width: 375px)': {
      right: -10,
    },
  },
  itemContainer: {
    '@media (max-width: 734px)': {
      paddingLeft: 5,
      paddingRight: 5,
    },
    '@media (min-width: 735px)': {
      maxWidth: 'inherit',
      flexBasis: 'inherit',
      marginLeft: 10,
      marginRight: 10,
    },
  },
  [YELLOW]: {
    color: COLORS.PRISM.YELLOW,
    borderColor: COLORS.PRISM.YELLOW,
    transition: 'all 350ms ease-in-out',
  },
  [PINK]: {
    color: COLORS.PRISM.PINK,
    borderColor: COLORS.PRISM.PINK,
    transition: 'all 350ms ease-in-out',
  },
  [PURPLE]: {
    color: COLORS.PRISM.PURPLE,
    borderColor: COLORS.PRISM.PURPLE,
    transition: 'all 350ms ease-in-out',
  },
  [GREEN]: {
    color: COLORS.PRISM.GREEN,
    borderColor: COLORS.PRISM.GREEN,
    transition: 'all 350ms ease-in-out',
  },
  [BLUE]: {
    color: COLORS.PRISM.BLUE,
    borderColor: COLORS.PRISM.BLUE,
    transition: 'all 350ms ease-in-out',
  },
  [LIGHT_BLUE]: {
    color: COLORS.PRISM.LIGHT_BLUE,
    borderColor: COLORS.PRISM.LIGHT_BLUE,
    transition: 'all 350ms ease-in-out',
  },
});
