
import React from 'react';
import { connect } from 'react-redux';

import {
  Typography,
  Grid,
  Paper,
  TextField,
  CssBaseline,
  Avatar,
  Button,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { PasswordActions } from '../../actions';

import styles from './AdminAskNewPassword.style';


const defaultErrors = {
  email: '',
}

class AdminAskNewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: {
        ...defaultErrors,
      },
    };
  }


  render() {
    const { emailSending } = this.props;
    const { email, submitted, errors } = this.state;

    return (
      <Grid container component="main" style={styles.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={styles.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={styles.paper}>
            <div style={styles.formWrapper}>
              <Avatar style={styles.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" >
                Reset password
              </Typography>
              <div style={styles.instruction}>
                {'Don\'t worry, happens to the best of us.'}<br/>
                {'Enter your email adress and we will send you a password reset link.'}
              </div>
              <form style={styles.form} noValidate>
                <TextField
                  error={!!errors.email}
                  helperText={errors.email}
                  disabled={emailSending}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={this.handleChange}
                />
                <div style={styles.buttonWrapper}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={styles.submit}
                    onClick={this.handleSubmit}
                    disabled={(!email || submitted) }
                  >
                    Email me the reset link
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      const { email } = this.state;
      const { askNewPassword } = this.props;

      if (this.validate()) {
        return askNewPassword(email)
          .then(() => this.setState({ submitted: false }));
      }

      this.setState({ submitted: false });
    });
  }

  validate = () => {
    const { email } = this.state;
    const errors = {};
    let isValid = true;

    if (!email) {
      isValid = false;
      errors.email = "Please enter a email address.";
    } else {
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(email)) {
        isValid = false;
        errors.email = 'Please enter valid email address.';
      }
    }

    this.setState({ errors: errors });

    return isValid;
  }
}

function mapState(state) {
  const { emailSending } = state.password;

  return { emailSending };
}

const actionCreators = {
  askNewPassword: PasswordActions.askNew,
};

const connectedAskNewPasswordPage = connect(mapState, actionCreators)(AdminAskNewPassword);
export default connectedAskNewPasswordPage;