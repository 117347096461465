export const adminModalConstants = {
    OPEN: 'ADMIN_MODAL_OPEN',
    CLOSE: 'ADMIN_MODAL_CLOSE',

    SET_FIRSTNAME: 'ADMIN_MODAL_SET_FIRSTNAME',
    SET_LASTNAME: 'ADMIN_MODAL_SET_LASTNAME',
    SET_EMAIL: 'ADMIN_MODAL_SET_EMAIL',
    SET_ROLE: 'ADMIN_MODAL_SET_ROLE',

    FETCH_ROLES_REQUEST: 'ADMIN_MODAL_FETCH_ROLES_REQUEST',
    FETCH_ROLES_SUCCESS: 'ADMIN_MODAL_FETCH_ROLES_SUCCESS',
    FETCH_ROLES_FAILURE: 'ADMIN_MODAL_FETCH_ROLES_FAILURE',
};
