
import React from 'react';
import { connect } from 'react-redux';

import {
  Typography,
  Grid,
  Paper,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Avatar,
  Button,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { history } from '../../helpers';
import { UserActions } from '../../actions';

import styles from './Login.style';


const defaultErrors = {
  email: '',
  password: '',
}

class Login extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();

    this.state = {
      email: '',
      password: '',
      errors: {
        ...defaultErrors,
      },
      submitted: false
    };
  }

  componentDidMount() {
    const { loginWithGoogle, loggedIn } = this.props;
    const { state } = history.location;

    if (state && state.code && !loggedIn)
      return loginWithGoogle(state.code);
  }

  componentDidUpdate(prevProps) {
    const { loggedIn } = this.props;
    const { loggedIn: prevLoggedIn } = prevProps;

    if (prevLoggedIn !== loggedIn)
      history.push('/home');
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, submitted, errors } = this.state;

    return (
      <Grid container component="main" style={styles.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={styles.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={styles.paper}>
            <div style={styles.titleContainer}>
              <Typography component="h1" variant="h4" style={styles.title}>
                  EF Finance Cockpit
              </Typography>
            </div>
            <div style={styles.formWrapper}>
              <Avatar style={styles.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" >
                Sign in
              </Typography>
              <form style={styles.form} noValidate>
                <TextField
                  error={!!errors.email}
                  helperText={errors.email}
                  disabled={loggingIn}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={this.handleChange}
                />
                <TextField
                  error={!!errors.password}
                  helperText={errors.password}
                  disabled={loggingIn}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={this.handleChange}
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <div style={styles.buttonWrapper}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={styles.submit}
                    onClick={this.handleSubmit}
                    disabled={(!email || !password || submitted) }
                  >
                    Sign In
                  </Button>
                  {loggingIn && <CircularProgress size={24} style={styles.buttonProgress} />}
                </div>
                <Grid container>
                  <Grid item xs>
                    <Link href="/user/new/passsword" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </form>
              <Divider
                variant="fullWidth"
                component='div'
                style={styles.divider}
              />
              <div style={styles.googleBtnWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<div style={styles.iconGoogle}/>}
                  style={!loggingIn ? styles.googleBtn : styles.googleBtnDisabled}
                  onClick={this.handleGoogleAuth}
                  disabled={loggingIn}
                >
                  Continue with Google
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      const { email, password } = this.state;
      const { login } = this.props;

      if (this.validate())
        login(email, password);

      this.setState({ submitted: false });
    });
  }

  handleGoogleAuth = (event) => {
    event.preventDefault();

    const url = this.getGoogleUrl();

    window.location.href = url;
  }

  buildQueryString = (queryObj) => {
    if (!queryObj || !Object.keys(queryObj).length)
      return '';

    const params = Object.keys(queryObj).map((q) => {
      const value = typeof queryObj[q] !== 'string' ? JSON.stringify(queryObj[q]) : queryObj[q];

      return `${encodeURIComponent(q)}=${encodeURIComponent(value)}`;
    });

    return `?${params.join('&')}`;
  }

  getGoogleUrl = () => {
    const {
      REACT_APP_EF_GOOGLE_CLIENT_ID: CLIENT_ID,
      REACT_APP_EF_GOOGLE_REDIRECT_URL: REDIRECT_URL,
      REACT_APP_EF_GOOGLE_SCOPES: GOOGLE_SCOPES,
    } = process.env;

    const stringifiedParams = this.buildQueryString({
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URL,
      scope: GOOGLE_SCOPES,
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
    });

    return `https://accounts.google.com/o/oauth2/v2/auth${stringifiedParams}`;
  }

  validate = () => {
    const { email, password } = this.state;
    const errors = {};
    let isValid = true;

    if (!email) {
      isValid = false;
      errors.email = "Please enter a email address.";
    } else {
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(email)) {
        isValid = false;
        errors.email = 'Please enter valid email address.';
      }
    }

    if (!password) {
      isValid = false;
      errors.password = "Please enter your password.";
    } else {
      if (password.length < 8){
          isValid = false;
          errors.password = 'A password should at least 8 characters long.';
      }
    }

    this.setState({ errors: errors });

    return isValid;
  }
}

function mapState(state) {
  const { loggingIn, loggedIn } = state.authentication;

  return { loggingIn, loggedIn };
}

const actionCreators = {
  login: UserActions.login,
  loginWithGoogle: UserActions.loginWithGoogle,
  logout: UserActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export default connectedLoginPage;