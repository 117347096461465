import React from 'react';
import ReactPlayer from 'react-player'

import styles from './BackgroundVideo.style';

export default class BackgroundVideo extends React.Component {
  render() {
    const { url } = this.props;

    if (!url)
      return null;

    return (
      <div style={styles.container} >
        <ReactPlayer
          url={url}
          playing={true}
          width='100%'
          height='100%'
          muted
          loop
          config={{
            youtube: {
              playerVars: { showinfo: 0 }
            },
          }}
          style={styles.video}
        />
      </div>
    );
  }
}
