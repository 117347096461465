import React from 'react';

import { connect } from 'react-redux';

import { Navbar } from '../../components';
import { AdminDrawer } from './AdminDrawer/AdminDrawer';
import AdminList from './sections/AdminList/AdminList';
import NotificationSender from './sections/NotificationSender/NotificationSender';
import ReportConfiguration from './sections/ReportConfiguration/ReportConfiguration';


import {
  Grid,
  CssBaseline,
} from '@material-ui/core';

import styles from './AdminSettings.style';


const PAGE_MAPPING = {
  'users-management': AdminList,
  'notification-sender': NotificationSender,
  'report-configuration': ReportConfiguration,
}

class AdminSettings extends React.Component {
  constructor() {
    super();

    this.state = {
      currentPage: 'users-management',
    };
  }

  componentDidMount() {
  }

  render() {
    const { currentPage } = this.state;

    const Page = PAGE_MAPPING[currentPage];

    return (
      <Grid style={styles.root}>
        <CssBaseline />
        <Navbar />
        <div style={styles.wrapper}>
          <AdminDrawer onItemClick={this.handleNavItemClick} />
          <Page />
        </div>
      </Grid>
    );
  }

  handleNavItemClick = (navId) => {
    this.setState({ currentPage: navId });
  }
}

function mapState(state) {
}

const actionCreators = {
};

const connectedAdminSettingsPage = connect(mapState, actionCreators)(AdminSettings);
export default connectedAdminSettingsPage;