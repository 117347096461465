import React from 'react';

import {
  Box,
  TextField,
  Checkbox,
} from '@material-ui/core';

import { Select } from '../../../../../../components';



import styles from './NotificationForm.style';


export class NotificationForm extends React.PureComponent {
  state = {
    subject: '',
    content: '',
    title: '',
    isAllUserTargeted: false,
    selectedUserIds: [],
  };

  _items = [];

  constructor(props) {
    super(props);

    const { users } = this.props;

    this._items = users.map(({ id, email }) => {
      return {
        id,
        label: email,
        value: `${id}`,
      };
    });
  }

  render() {
    const { errors } = this.props;
    const { subject, title, content, selectedUserIds, isAllUserTargeted } = this.state;

    return (
      <div style={styles.wrapper}>
        <div style={styles.mailToContainer}>
          <label style={styles.targetLabel}>Targeted users</label>
          {!isAllUserTargeted ? (
            <Select
              type="large"
              selectedIds={selectedUserIds}
              canSearch
              items={this._items}
              onSelect={this.handleSelection}
              style={styles.userSelect}
            />
          ) : null}
          <div style={styles.allUsersOption}>
            <Checkbox
              color="primary"
              checked={isAllUserTargeted}
              onChange={this.handleToggleAllUserTarget}
            />
            <span style={styles.allUserLabel}>All users</span>
          </div>
        </div>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          style={styles.formContainer}
        >
          <TextField
            error={errors && errors.subject}
            id="notification-subject"
            label="Subject"
            placeholder="What is the purpose of this message?"
            value={subject}
            onChange={this.handleSubjectChange}
            helperText={errors.subject}
            style={styles.field}
          />
          <TextField
            error={errors && errors.title}
            id="notification-title"
            label="Title"
            placeholder="Define the content title here..."
            value={title}
            onChange={this.handleTitleChange}
            helperText={errors.title}
            style={styles.field}
          />
          <TextField
            error={errors && errors.content}
            id="notification-content"
            multiline
            label="Content"
            placeholder="Type a message to send here..."
            value={content}
            maxRows={30}
            onChange={this.handleContentChange}
            helperText={errors.content}
            InputProps={{ style: styles.multiLineInput }}
            style={styles.field}
          />
        </Box>
      </div>
    );
  }

  handleToggleAllUserTarget = () => {
    const { onTargetChange, users } = this.props;
    const { isAllUserTargeted, selectedUserIds } = this.state;

    this.setState({ isAllUserTargeted: !isAllUserTargeted }, (value) => {
      const ids = !isAllUserTargeted
        ? users.map((user) => user.id)
        : selectedUserIds;

      onTargetChange(ids);
    });
  };

  handleSelection = (selection) => {
    const { onTargetChange } = this.props;
    const { selectedUserIds } = this.state;
    const { value } = selection;
    const idSelected = parseInt(value);

    const selectedIds = selectedUserIds.includes(idSelected)
      ? selectedUserIds.filter((id) => id !== idSelected)
      : selectedUserIds.concat([idSelected]);

    this.setState({ selectedUserIds: selectedIds }, () => {
      onTargetChange(selectedIds);
    });
  };

  handleSubjectChange = (event) => {
    const { onSubjectChange } = this.props;
    const { value } = event.target;

    this.setState({ subject: value }, () => {
      onSubjectChange(value);
    });
  };

  handleTitleChange = (event) => {
    const { onTitleChange } = this.props;
    const { value } = event.target;

    this.setState({ title: value }, () => {
      onTitleChange(value);
    });
  };

  handleContentChange = (event) => {
    const { onContentChange } = this.props;
    const { value } = event.target;

    this.setState({ content: value }, () => {
      onContentChange(value);
    });
  };
}

export default NotificationForm;
