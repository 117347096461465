// @flow

/**
 * Display a select input
 *
 * Props:
 * - selected: The item selected by user, can be null if user has not selected item yet
 * - placeholder: The text to display when selection is null
 * - items: The items to display in the item box
 * - label: Set a input label
 * - onSelect: Function called when an item is selected
 * - type: Defines if the component should be small or large
 * - canSearch: Boolean that enable or disable the search
 * - onCreate: Callback called after an itemn creation
 * - style: override style of the component
 */

import * as React from 'react';
import { memoize } from 'lodash';
import { css } from 'aphrodite';
import {
  InputBase,
} from '@material-ui/core';

import {
  ArrowDropDown as ArrowDownIcon
} from '@material-ui/icons';

import { DropdownItem } from '../DropdownItem/DropdownItem';

import { COLORS } from '../../foundation';

import styles, { extendedStyles } from './Select.style';


const KeyInteractionType = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
};

const REGEX_ACCENTS = /[\u0300-\u036f]/g;

export class Select extends React.Component {
  static defaultProps = {
    label: '',
    selectedIds: [],
    placeholder: 'Select an item',
    type: 'large',
    canSearch: false,
    style: undefined,
  };

  handleClick = memoize((item) => () => {
    const { onSelect, items, selectedIds: selectedUserIds } = this.props;
    const { itemMap } = this.state;
    const { id: idSelected} = item;

    if (this._inputRef && this._inputRef.current)
      this._inputRef.current.blur();

    const selectedIds = selectedUserIds.includes(idSelected)
      ? selectedUserIds.filter((id) => id !== idSelected)
      : selectedUserIds.concat([idSelected]);

    const value = selectedIds.map((id) => itemMap[id].label);

    this.setState({
      expanded: false,
      focused: false,
      isWriting: false,
      value: value.join(', '),
      filteredItems: items.map((it) => ({ ...it })),
    }, () => onSelect(item));
  });

  setActiveKey = memoize((key) => () => {
    this.setState({ activeKey: key });
  })

  unsetActiveKey = memoize((key) => () => {
    this.setState(state => state.activeKey === key
      ? { activeKey: '' }
      : state
    );
  })

  _wrapperRef = React.createRef();
  _listRef = React.createRef();
  _inputRef = React.createRef();
  _itemRefs= {};

  constructor(props: Props) {
    super(props);

    const { items } = props;

    const itemMap = items.reduce((map, elem) => {
      map[elem.id] = elem;

      return map;
    }, {});

    this.state = {
      activeKey: '',
      expanded: false,
      focused: false,
      value: '',
      isWriting: false,
      itemMap: itemMap,
      filteredItems: items.map((item) => ({ ...item })),
      isMouseUsed: true,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentDidUpdate() {
    const { isMouseUsed } = this.state;

    // INFO disable auto-scroll when mouse hovering to prevent side effects
    if (!isMouseUsed)
      this.scrollIntoActiveItems();

    this.handleFocus();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const { style, selectedIds, type } = this.props;
    const { focused } = this.state;

    let headerStyle = { ...styles.headerContainer, ...styles.headerContainerType[type] };

    if (focused || selectedIds.length)
      headerStyle = { ...headerStyle, ...styles.focusedHeader };

    return (
      <div
        ref={this._wrapperRef}
        style={{ ...styles.wrapper, ...style }}
      >
        {this.renderLabel()}
        <div style={headerStyle} onClick={this.handleToggleExpand}>
          {this.renderSelectContent()}
          <div style={styles.iconContainer}>
            <ArrowDownIcon />
          </div>
        </div>
        {this.renderSelectBox()}
      </div>
    );
  }

  renderLabel = () => {
    const { label } = this.props;

    if (!label)
      return null;

    return (
      <div style={styles.label}>
        {`${label}`}
      </div>
    );
  }

  /* eslint-disable complexity */
  renderSelectContent = () => {
    const { selectedIds, placeholder, type, canSearch } = this.props;
    const { value, focused, isWriting } = this.state;

    let content = value;

    let newPlaceholder = placeholder;

    if (focused && !isWriting && canSearch && value) {
      content = '';
      newPlaceholder = value;
    }

    const color = (!selectedIds.length && !value.length) || (focused && !isWriting && canSearch)
      ? COLORS.TEXT.PLACEHOLDER_DEFAULT
      : COLORS.TEXT.DEFAULT;
    const style = {
      ...styles.headerContent,
      ...styles.headerContentType[type],
      color,
      cursor: canSearch ? 'initial' : 'pointer',
    };

    return (
      <InputBase
        ref={this._inputRef}
        type="text"
        value={content}
        placeholder={newPlaceholder}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleInputValue}
        onFocus={this.handleFocus}
        style={style}
        autoComplete="off"
        className={css(extendedStyles.input)}
      />
    );
  }

  renderSelectBox = () => {
    const { expanded } = this.state;

    if (!expanded)
      return null;

    return (
      <div
        ref={this._listRef}
        onMouseMove={this.handleMouseMove}
        onKeyDown={this.handleKeyDown}

        // INFO tabIndex is required to handle keydown events
        tabIndex={0}
        style={styles.itemBox}
      >
        {this.renderNotFoundCell()}
        {this.renderItems()}
      </div>
    );
  }

  renderItems = () => {
    const { selectedIds } = this.props;
    const { activeKey, filteredItems, isMouseUsed } = this.state;

    return filteredItems.map((item, index) => {
      const key = this.makeKey(item);
      const text = item.label;
      const isSelected = Boolean(selectedIds.find((id) => id === item.id));


      let containerStyle = styles.item;

      this._itemRefs[key] = React.createRef();

      if (key === activeKey)
        containerStyle = { ...containerStyle, ...styles.itemActive };

      return (
        <div
          ref={this._itemRefs[key]}
          key={key}
          style={containerStyle}
        >
          <DropdownItem
            id={index}
            text={text}
            selected={isSelected}
            onClick={this.handleClick(item)}
            style={styles.item}
            onHover={this.handleItemHover}
            removeMouseFocus={!isMouseUsed}
          />
        </div>
      );
    });
  }

  renderNotFoundCell = () => {
    const { filteredItems } = this.state;
    const text = 'No result found';
    const style = {
      ...styles.item,
      ...styles.notFound,
    };

    if (filteredItems.length >= 1)
      return null;

    return (
      <div style={style}>
        {text}
      </div>
    );
  }

  handleItemHover = (id: number) => {
    const { filteredItems } = this.state;

    this.setState({ activeKey: this.makeKey(filteredItems[id]) });
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this._wrapperRef.current && event.target instanceof Node && !this._wrapperRef.current.contains(event.target))
      this.handleReset();
  }

  handleToggleExpand = () => {
    const { expanded, filteredItems } = this.state;

    const newExpanded = !expanded;

    this.setState({ expanded: newExpanded, focused: newExpanded });

    if (newExpanded && filteredItems.length)
      this.setState({ activeKey: this.makeKey(filteredItems[0]) });
  }

  /* eslint-disable complexity */
  handleKeyDown = (e: KeyboardEvent) => {
    const { key } = e;

    switch (key) {
      case KeyInteractionType.UP:
      case KeyInteractionType.DOWN: {
        // INFO prevent scrolling the box with keyboard
        e.preventDefault();

        const { filteredItems } = this.state;
        const nextIndex = this.makeNextIndex(key);

        this.setState({ activeKey: this.makeKey(filteredItems[nextIndex]) });

        break;
      }
      case KeyInteractionType.ENTER: {
        e.preventDefault();

        const { activeKey, filteredItems } = this.state;
        const item = filteredItems.find(x => this.makeKey(x) === activeKey);

        if (this._inputRef && this._inputRef.current)
          this._inputRef.current.blur();

        if (item)
          this.handleClick(item)();

        break;
      }
      case KeyInteractionType.ESCAPE: {
        e.preventDefault();

        if (this._inputRef && this._inputRef.current)
          this._inputRef.current.blur();

        this.handleReset();

        break;
      }
      default:
        break;
    }

    this.setState({ isMouseUsed: false });
  }

  handleReset = (label = '') => {
    const { items, selectedIds } = this.props;
    const { itemMap } = this.state;
    const value = selectedIds.map((id) => itemMap[id].label);

    this.setState({
      expanded: false,
      focused: false,
      isWriting: false,
      value,
      filteredItems: items.map((it) => ({ ...it })),
    });
  }

  handleInputValue = (event) => {
    const { value } = event.target;
    const { items } = this.props;
    const filteredItems = !value
      ? items.map((item) => ({ ...item }))
      : items.filter((item) => this.handleItemsFilter(item, value));
    const newActiveKey = filteredItems.length ? this.makeKey(filteredItems[0]) : null;

    this.setState({ value, isWriting: true, filteredItems, activeKey: newActiveKey });
  }

  handleItemsFilter = (item, value) => {
    const itemWithoutAccents = item.label.normalize('NFD').replace(REGEX_ACCENTS, '');
    const valueWithoutAccents = value.normalize('NFD').replace(REGEX_ACCENTS, '');

    return itemWithoutAccents.toLowerCase().startsWith(valueWithoutAccents.toLowerCase());
  }

  handleMatchStrictlyItem = (item, value) => {
    const itemWithoutAccents = item.label.normalize('NFD').replace(REGEX_ACCENTS, '');
    const valueWithoutAccents = value.normalize('NFD').replace(REGEX_ACCENTS, '');

    return itemWithoutAccents.toLowerCase() === valueWithoutAccents.toLowerCase();
  }

  handleMouseMove = () => {
    this.setState({ isMouseUsed: true });
  }

  handleFocus = () => {
    const { canSearch } = this.props;
    const { focused } = this.state;

    if (!(this._inputRef && this._inputRef.current))
      return;

    if (!canSearch && this._listRef && this._listRef.current) {
      this._inputRef.current.blur();

      // $FlowIssue ref use to focus the div in the case of input disable
      this._listRef.current.focus();
    } else if (canSearch && !focused)
      this._inputRef.current.blur();
    else if (canSearch && focused)
      this._inputRef.current.focus();
  }

  makeKey = (item) => {
    return `${item.id}-${item.value}`;
  }

  makeNextIndex = (key) => {
    const { activeKey, filteredItems } = this.state;
    const index = filteredItems.findIndex(x => this.makeKey(x) === activeKey);

    if (key === KeyInteractionType.DOWN) {
      return index >= 0
        ? (index + 1) % filteredItems.length
        : 0;
    }

    return index >= 0
      ? (index + filteredItems.length - 1) % filteredItems.length
      : filteredItems.length - 1;
  }

  scrollIntoActiveItems = () => {
    const { activeKey, filteredItems } = this.state;

    const item = filteredItems.find(x => this.makeKey(x) === activeKey);

    if (item) {
      const ref = this._itemRefs[this.makeKey(item)];

      if (ref && ref.current)
        ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  }
}

export default Select;