import { ROLES } from '../utils/Enum';


export default {
  roleMatchRoute,
};

const rules = {
  [ROLES.UNAUTHENTICATED]: {
    static: [
      'dashboard:login',
    ]
  },
  [ROLES.ADMIN]: {
    static: [
      'dashboard:home',
      'reports:assets',
      'reports:financial-overview',
      'reports:od',
      'reports:ed',
      'reports:focus-area',
      'reports:ef-ers',
      'admin:settings',
    ],
  },
  [ROLES.LEVEL0]: {
    static: [
      'dashboard:home',
      'reports:assets',
      'reports:financial-overview',
      'reports:od',
      'reports:ed',
      'reports:focus-area',
      'reports:ef-ers',
    ],
  },
  [ROLES.LEVEL1]: {
    static: [
      'dashboard:home',
    ],
  },
  [ROLES.LEVEL2]: {
    static: [
      'dashboard:home',
    ],
  },
};

function roleMatchRoute(roleId, route) {
  return rules[roleId] && rules[roleId].static.includes(route);
}