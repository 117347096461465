import { COLORS, TYPOGRAPHY } from '../../../../../../foundation';

export default {
  dialogTitle: {
    ...TYPOGRAPHY.H1,
    fontWeight: 'bold',
  },
  nameForm: {
    display: 'flex',
  },
  lastNameInput: {
    marginLeft: 20,
  },
  infoForm: {
    display: 'flex',
    marginTop: 15,
  },
  emailInput: {
    margin: 0,
    width: '70%',
  },
  roleFormControl: {
    marginLeft: 20,
    width: '30%',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorMessage: {
    marginLeft: 10,
    color: COLORS.ERROR.DEFAULT,
  },
  saveButton: {
    marginLeft: 5,
  },
};
