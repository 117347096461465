// import cookie from 'react-cookies';

// export const getCsrfToken = () => {
//   return {
//     'csrf-token': cookie.load('csrf-ef-finance-cockpit'),
//   }
// };

export const buildBaseHeaders = (method) => {
  return {
    ...((['POST', 'PUT', 'PATCH'].includes(method)) ? { 'Content-Type': 'application/json' } : {}),
    // ...getCsrfToken(),
  };
}