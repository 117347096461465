
import React from 'react';

import { connect } from 'react-redux';

import { Loader } from '../../../../components';
import { AdminItem, AdminEditionModal } from './components';

import userService from '../../../../services/user.service';
import { UserActions, AdminModalActions } from '../../../../actions';

import {
  Grid,
  CssBaseline,
  Typography,
  Fab,
  Icon
} from '@material-ui/core';

import styles from './AdminList.style';


class AdminList extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { usersFetch } = this.props;

    return Promise.resolve(usersFetch());
  }

  render() {
    return (
      <>
        {this.renderContent()}
        <AdminEditionModal
          onClose={this.handleCloseModal}
          onSave={this.handleSave}
        />
      </>
    );
  }

  renderContent = () => {
    const { loading } = this.props;

    if (!loading)
      return this.renderList();

    return (
      <div style={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  renderList = () => {
    return (
      <div style={styles.wrapper}>
        <div style={styles.contentWrapper}>
          <div styles={styles.titleWrapper}>
            <Typography variant="h3" component="h3" style={styles.pageTitle}>
              Users management
            </Typography>
          </div>
          <Grid container justify="center" spacing={3} style={styles.listContainer}>
            {this.renderAdminGrid()}
          </Grid>
        </div>
        <Fab color="primary" aria-label="add" style={styles.addButton} onClick={() => this.handleOpenModal()}>
          <Icon className="fa fa-user-plus" style={styles.iconAdd}/>
        </Fab>
      </div>
    )
  }

  renderAdminGrid = () => {
    const { users } = this.props;

    if (!users) {
      return (
        <div style={styles.emptyState}>
          <Icon className={`fa fa-users`} style={styles.placeholderIcon}/>
          <Typography variant="h5" component="h5" style={styles.placeholderText}>
            There is no user to manage yet. Please start to add one.
          </Typography>
        </div>
      );
    };

    return users.map((user) => {
      const { email, firstName, lastName, verified, id, role, lastInvitationDate } = user;

      const {
        isValid: canResendInvite,
        error: resendImpossibleMessage
      } = userService.isInvitationCouldBeResent({ lastInvitationDate })

      console.log({ resendImpossibleMessage })
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`admin-card-${id}`} style={styles.itemWrapper}>
          <AdminItem
            name={`${firstName} ${lastName}`}
            email={email}
            verified={verified}
            role={role.label}
            canResendInvite={canResendInvite}
            resendImpossibleMessage={resendImpossibleMessage}
            onEdit={() => this.handleOpenModal(user)}
            onDelete={() => this.handleDelete(user)}
            onResendInvite={() => this.handleResend(user)}
          />
        </Grid>
      );
    });
  }

  handleSave = (user) => {
    const { createAdmin, updateAdmin } = this.props;
    const { id, ...newUser } = user;

    if (!id)
      return createAdmin(newUser);

    return updateAdmin(user);
  }

  handleDelete = (user) => {
    const { deleteAdmin } = this.props;
    const { id } = user;

    return deleteAdmin(id);
  }

  handleResend = (user) => {
    const { resendInvitation } = this.props;
    const { id } = user;

    return resendInvitation(id);
  }

  handleOpenModal = (user) => {
    const { openModal } = this.props;

    return openModal(user);
  }

  handleCloseModal = (user) => {
    const { closeModal } = this.props;

    return closeModal();
  }
}

function mapState(state) {
  const { items: users, loading } = state.users;

  return { users, loading };
}

const actionCreators = {
  usersFetch: UserActions.fetch,
  createAdmin: UserActions.create,
  deleteAdmin: UserActions.delete,
  updateAdmin: UserActions.update,
  openModal: AdminModalActions.open,
  closeModal: AdminModalActions.close,
  resendInvitation: UserActions.resendInvitation,
};

const connectedAdminListSection = connect(mapState, actionCreators)(AdminList);
export default connectedAdminListSection;