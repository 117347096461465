import { StyleSheet } from 'aphrodite';

import { PRISM_PART } from '../../utils/Enum';
import { COLORS } from '../../foundation';

const { YELLOW, PINK, PURPLE, GREEN, BLUE, LIGHT_BLUE } = PRISM_PART;

export default {
  wrapper: {
    background: 'transparent',
  },
  prism: {
    cursor: 'pointer',
    opacity: 1,
    fill: 'transparent',
    // position: 'relative',
  },
  [YELLOW]: {
    zIndex: 1,
  },
  [PINK]: {
    zIndex: 2
  },
  [PURPLE]: {
    zIndex: 2,
  },
  [GREEN]: {
    zIndex: 1,
  },
  [BLUE]: {
    zIndex: 2,
  },
  [LIGHT_BLUE]: {
    zIndex: 1,
  },
  tooltip: {
    fontWeight: 'bold',
    padding: '0 10px',
  }
};

export const extendedStyles = StyleSheet.create({
  prism: {
    transition: 'all 300ms ease-in-out',
    ':hover': {
      opacity: 0.8,
    },
  },
  [YELLOW]: {
    ':hover': {
      fill: COLORS.PRISM.YELLOW,
    },
  },
  [PINK]: {
    ':hover': {
      fill: COLORS.PRISM.PINK,
    },
  },
  [PURPLE]: {
    ':hover': {
      fill: COLORS.PRISM.PURPLE,
    },
  },
  [GREEN]: {
    ':hover': {
      fill: COLORS.PRISM.GREEN,
    },
  },
  [BLUE]: {
    ':hover': {
      fill: COLORS.PRISM.BLUE,
    },
  },
  [LIGHT_BLUE]: {
    ':hover': {
      fill: COLORS.PRISM.LIGHT_BLUE,
    },
  },
  [`selected-${YELLOW}`]: {
    fill: COLORS.PRISM.YELLOW,
  },
  [`selected-${PINK}`]: {
    fill: COLORS.PRISM.PINK,
  },
  [`selected-${PURPLE}`]: {
    fill: COLORS.PRISM.PURPLE,
  },
  [`selected-${GREEN}`]: {
    fill: COLORS.PRISM.GREEN,
  },
  [`selected-${BLUE}`]: {
    fill: COLORS.PRISM.BLUE,
  },
  [`selected-${LIGHT_BLUE}`]: {
    fill: COLORS.PRISM.LIGHT_BLUE,
  },
});
