import { COLORS, TYPOGRAPHY } from '../../foundation';

export default {
  root: {
    width: '100%',
    minHeight: '101vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  backgroundLogo: {
    height: '100%',
    position: 'absolute',
    width: '32%',
    top: 10,
    left: '1%',
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/ef-diamond-min.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center',
  },
  overlay: {
    background: '-webkit-gradient(linear, right top, left top, from(rgba(250,250,250,1)), color-stop(92%, rgba(250,250,250,0.2)))',
    height: '100%',
    width: '100%'
  },
  loaderWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: COLORS.WHITE.DEFAULT,
    zIndex: 100,
  },
  contentWrapper: {
    width: '50%',
    minHeight: '50%',
    borderTopWidth: 4,
    borderTopColor: COLORS.TRUE_BLACK.DEFAULT,
    borderTopStyle: 'solid',
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  errorContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  errorImage: {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/broken-link.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: 160,
    width: 160,
  },
  errorContent: {
    marginLeft: 16,
  },
  title: {
    ...TYPOGRAPHY.HERO,
    fontWeight: 'bold',
  },
  description: {
    marginTop: 16,
    ...TYPOGRAPHY.PR,
    color: COLORS.TEXT.SECONDARY_DEFAULT,
    textAlign: 'justify',
  },
  errorButton: {
    backgroundColor: COLORS.ERROR.DEFAULT,
    color: COLORS.WHITE.DEFAULT,
  },
  headForm: {
    display: 'flex',
    alignItems: 'center',
  },
  headTitle: {
    ...TYPOGRAPHY.HERO,
    marginLeft: 14,
    fontWeight: 'bold',
  },
  form: {
    marginTop: 24,
    maxWidth: 400,
  },
  submit: {
    marginTop: 16,
  }
};
