import { COLORS, TYPOGRAPHY } from '../../../../foundation';


export default {
  wrapper: {
    width: '100%',
  },
  contentWrapper: {
    marginTop: 72,
    padding: 30,
    width: '100%',
  },
  loaderWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: COLORS.WHITE.DEFAULT,
    zIndex: 100,
  },
  titleWrapper: {
    marginTop: 10,
  },
  pageTitle: {
    ...TYPOGRAPHY.HERO,
    fontWeight: 'bold',
    padding: 5,
  },
  listContainer: {
    marginTop: 64,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  placeholderIcon: {
    fontSize: 64,
    width: '100%',
    textAlign: 'center',
  },
  placeholderText: {
    marginTop: 20,
    fontSize: 18,
    color: COLORS.TEXT.SECONDARY_DEFAULT
  },
  itemWrapper: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  addButton: {
    position:  'absolute',
    bottom: 20,
    right: 20,
  },
  iconAdd: {
    width: '100%',
  },
};
